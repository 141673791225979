import { Center, Container, ScrollView, View } from "native-base";
import React, { createElement, useEffect, useLayoutEffect } from "react";
import { Platform } from "react-native";
import Footer from "./Footer";


export default function Layout(props) {

    useLayoutEffect(() => {
        if (Platform.OS === "web") { 
            // showAlert("WE ON WEB BABEH
            const script = document.createElement("script")
            script.setAttribute('type', 'text/javascript');
            script.text = ``

            const headList = document.getElementsByTagName("head");
            if (headList.length > 0) { 
                const head = headList[0]
                head.appendChild(script)
            }
        }
    }, [])

    var children = <><Center flex={1} h="100%" w="100%" minH={props.minH ?? Platform.OS == "web" ? 1200 : 500}>
        <Container {...props.containerProps} maxW="700" w={props.containerW ? props.containerW : "93%"} flex={1} minH="100%">
            {props.children}
        </Container>
    </Center>
        {Platform.OS === "web" && !props.hideFooter && <Footer />}
    </>

    if (props.ignoreContainer) {
        children = <>
        {props.children}
        {Platform.OS === "web" && !props.hideFooter && <Footer />}
        </>
    }

    if (props.scrollView) {
        return <ScrollView bg="white" flex={1} {...props.viewProps} keyboardShouldPersistTaps="always">
            {children}
        </ScrollView>
    }
    return <View bg="white" flex={1} {...props.viewProps}>
        {children}
    </View>
}


export function CenterLayout(props) {
    return <><Center flex={1} h="100%" w="100%" minH={700}>
    <Container {...props.containerProps} maxW="700" w={props.containerW ? props.containerW : "90%"} flex={1} minH="100%">
        {props.children}
    </Container>
</Center>
    {Platform.OS === "web" && !props.hideFooter && <Footer />}
</>
}
