import { Heading, HStack, View, Text, Center, Container, Button, Divider, useBreakpointValue, Icon } from "native-base";
import React from "react";
import useViewType from "../hooks/useViewType";
// import { A } from '@expo/html-elements';
import BaseApiConfigProvider from '../api/BaseApiConfigProvider'
import { Linking, Image } from "react-native";
import { Entypo } from "@expo/vector-icons";
// import Config from "../constants/Config";

export default function Footer(props) {

    var viewType = useViewType()

    const flexDir = useBreakpointValue({
        base: 'column',
        lg: 'row',
    });

    return <Center mt="10" bgColor={"gray.100"} w="100%">
        <Container maxW="700" w="90%" mt="4" pb="5">
            <View w="100%" display={"flex"} pt="8" flexDir={flexDir} justifyContent="space-between">
                <View>
                    {/* <Image source={require("../assets/images/fulltrip_text_red.png")} style={{height: 50, width: 100, resizeMode: 'contain'}}></Image> */}
                    {/* <Text bold>Get your trip out of the group chat</Text> */}
                    <HStack mt="2">
                        <Button  leftIcon={<Icon as={Entypo} name="instagram"></Icon>}  variant={"link"} onPress={() => Linking.openURL("https://www.instagram.com/onfulltrip")}></Button>
                        <Button  leftIcon={<Icon as={Entypo} name="twitter"></Icon>}  variant={"link"} onPress={() => Linking.openURL("https://www.twitter.com/onfulltrip")}></Button>
                    </HStack>
                    <Text mt={3}>Made with ❤️ in Los Angeles, CA</Text>
                </View>
                {viewType === "mobile" && <Divider mt="10" mb="10"></Divider>}
                <View>
                    {/* <A style={{ marginBottom: 10 }} href={`${BaseApiConfigProvider.getWebUrl()}`}>Download</A>
                    <A style={{ marginBottom: 10 }} href={`${BaseApiConfigProvider.getWebUrl()}/privacy`}>Privacy policy</A>
                    <A style={{ marginBottom: 10 }} href={`${BaseApiConfigProvider.getWebUrl()}/terms`}>Terms of use</A> */}
                </View>
            </View>

            <Divider mb={10} mt={10}></Divider>

            <Text>All rights reserved, TheRoaming, Inc 2023</Text>
            {/* <Text fontSize={"xs"}>v{Config.internalVersion}</Text> */}
        </Container>
    </Center>
}