import { initializeApp } from 'firebase/app';
import { getAuth, initializeAuth, getReactNativePersistence, signInWithRedirect, GoogleAuthProvider, browserLocalPersistence, browserPopupRedirectResolver  } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Plaform, Platform } from "react-native";

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

export function initializeFirebase() { 
    const platform = Platform.OS
    console.log("----PLATFORM IS -----", platform)
    const firebaseConfig = {
        apiKey: "AIzaSyDiSCgQWGOkJhfUo31CvS0Yba5BbqAN3YU",
        authDomain: platform == "web" ? "auth.reminderbase.com" : "app-authentication-e1ee7.firebaseapp.com",
        databaseURL: "https://app-authentication-e1ee7.firebaseio.com",
        projectId: "app-authentication-e1ee7",
        storageBucket: "app-authentication-e1ee7.appspot.com",
        messagingSenderId: "700623114287",
        appId: "1:700623114287:web:a4091e41724e51bf6a76da"
      };
      
    const app = initializeApp(firebaseConfig);
    // For more information on how to access Firebase in your project,
    // see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
    
    console.log("======FIREBASE APP INITIALIZED======")
    // Initialize Firebase Authentication and get a reference to the service
    const config = {
      persistence: browserLocalPersistence,
    }
    if (platform !== "web") {
      config.persistence = getReactNativePersistence(AsyncStorage);
    } else { 
      config.popupRedirectResolver = browserPopupRedirectResolver
    }
    
    initializeAuth(app, config);

    return app
}