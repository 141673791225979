import { HStack, Text, Button, Container, Center, View, Menu, Pressable, HamburgerIcon, Heading, Image } from "native-base";
import React from "react";
import { useSelector } from "react-redux";
import useViewType from "../hooks/useViewType";
import BaseApiConfigProvider from '../api/BaseApiConfigProvider' 
import { Linking } from "react-native";

export default function WebHeader({ props }) {
    //TODO: Handle user that is NOT logged in + show user avatar!
    const user = useSelector((s: any) => s.user)
    const view = useViewType()

    const signedInItems = [
        { name: "Create Reminder", page: "Draft", theme: "primary" },
        { name: "Home", page: "Root" },
        { name: "Contacts", page: "Contacts" },
        { name: "Chats", page: "Chats" },
        { name: "Setting", page: "Setting"}
    ]

    const notSignedInItems = [
        { name: "Download", page: "Login" },
        // { name: "Login", page: "Login" }
    ]

    const logout = async () => {
        await BaseApiConfigProvider.removeUserToken();
        props.navigation.navigate("Login");
    }


    function getRenderForNavItem(item: any) {
        if (item.name === "Setting") { 
            return <Menu trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Button variant={item.theme ? null : "link"} colorScheme={item.theme ? item.theme : null} {...triggerProps}>{item.name}</Button>
                      </Pressable>;
              }}>
                <Menu.Item onPress={() => props.navigation.navigate(item.page)}>View profile</Menu.Item>
                <Menu.Item onPress={logout}>Logout</Menu.Item>
            </Menu>
        } else { 
            let onPress = () => props.navigation.navigate(item.page);
            if (item.name == "Download") { 
                onPress = () => Linking.openURL("https://4vq5c.app.link/download")
            }
            return <Button variant={item.theme ? null : "link"} colorScheme={item.theme ? item.theme : null} onPress={onPress}>{item.name}</Button>
        }
    }


    function getMobileRender(item){
        if (item.name === "Profile") { 
            return <Menu trigger={triggerProps => {
                return <Button variant={item.theme ? null : "link"} colorScheme={item.theme ? item.theme : null} {...triggerProps}>{item.name}</Button>
              }}>
                <Menu.Item onPress={() => props.navigation.navigate(item.page)}>View profile</Menu.Item>
                <Menu.Item onPress={logout}>Logout</Menu.Item>
            </Menu>
        } else { 
            let onPress = () => props.navigation.navigate(item.page);
            if (item.name == "Download") { 
                onPress = () => Linking.openURL("https://4vq5c.app.link/download")
            }
            return <Menu.Item onPress={onPress}>{item.name}</Menu.Item>
        }
    }


    return <Center bg="white" borderWidth={1} h="16" borderColor={"gray.300"} borderBottomColor={"gray.300"}>
        <Container  maxW="700" w="90%" display="flex" justifyContent={'center'}>
            <HStack width="100%" bg="white" height="100%" style={{alignItems: 'center'}}>
                <View>
                    <Image source={require("../assets/icon.png")} style={{height: 30, width:30, resizeMode: 'contain'}}></Image>
                </View>


                <HStack h="10" flexDirection="row" justifyContent={"flex-end"} width="95%" alignItems={"center"}>
                    {view == "mobile" && !props.hideDownload && <Menu trigger={triggerProps => {
                        console.log(triggerProps)
                        return <Pressable mr="3" accessibilityLabel="More options menu" {...triggerProps}>
                            <HamburgerIcon size="md" mr="3" />
                        </Pressable>;
                    }}>
                        {user && signedInItems.map((item) => getMobileRender(item))}
                        {!user && !props.hideDownload && notSignedInItems.map((item) => getMobileRender(item))}
                    </Menu>}

                    {view === "desktop" && <>
                        {user && signedInItems.map((item) => getRenderForNavItem(item))}
                        {!user && !props.hideDownload && notSignedInItems.map((item) => getRenderForNavItem(item))}
                    </>
                    }
                </HStack>
            </HStack>
        </Container>
    </Center>
}