import { Modal, Text, Button, HStack } from "native-base";
import SimpleModal from "./SimpleModal";


export default function ConfirmModal(props){

    return <SimpleModal isOpen={props.isOpen} onClose={props.onClose} size={props.size} headerTitle={props.headerTitle ?? props.title} getFooter={() => <HStack space={2}><Button onPress={props.onConfirm} colorScheme={props.confirmScheme ?? "primary"}>{props.confirmLabel ?? "Save"}</Button><Button onPress={props.onClose} colorScheme={"coolGray"}>Cancel</Button></HStack>}>
        {!props.children && <Text>{props.text ?? props.message}</Text>}
        {props.children}
    </SimpleModal>
}