import { FlatList, Pressable, HStack, Heading, View, Text, Spinner, Button, Divider } from "native-base";
import Layout from "../components/Layout";
import { Alert, Linking, Share } from "react-native";
import { useCallback, useEffect, useState } from "react";
import Placeholder from "../Constants/Placeholder";
import { getContactListShareLink, openWebUrl } from "../utils";
import { useSelector } from "react-redux";
import Colors from "../Constants/Colors";
import { canAccessFeature } from "../utils/AccessUtil";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { useFocusEffect } from "@react-navigation/native";
import Analytics from "../utils/AnalyticsManager";
import Data from "../Constants/Data";


export default function ContactsScreen(props) { 

    const contactListApi = new GenericViewSetAPI("contact_list")
    const allContact = {
        id: -1,
        name: "All contacts"
    }
    const [contacts, setContacts]  = useState(null)
    const [page, setPage] = useState(1)
    const [contactsResp, setContactsResp] = useState(null)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    
    const user = useSelector((state: any) => state.user)
    const config = useSelector((state: any) => state.config)
    
    useFocusEffect(useCallback(() => {
        let isActive = true
        Analytics.logEvent("view_page", {page: "ContactsScreen"})
        getContacts()
        return () => {
            isActive = false;
          };
    }, []))

    useEffect(() => {
        props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={() => {
            props.navigation.push("ContactsDraft")
        }}>Create</Button>})
    }, [user])


    async function getContacts(page=1, overwrite = true) { 
        const contactsResp = await contactListApi.query({page: page, page_size: Data.pageSize})
        console.log("contactsResp")
        console.log(contactsResp)
        if (contactsResp && !contactsResp.error) { 
            setContactsResp(contactsResp)
            if (overwrite) {
                setContacts(contactsResp.results)
                setPage(1)
            } else {
                setContacts([...contacts, ...contactsResp.results])
            }
        } else {
            //TOOD: Maybe not do this?
            setContacts([])
        }
    }

    async function refreshContacts() { 
        setRefreshLoading(true)
        await getContacts(1)
        setRefreshLoading(false)
    }

    async function handleOnEndReached() { 
        console.log("ON END RECHED", contactsResp)
        if (contactsResp.next) { 
            const newPage = page + 1
            await getContacts(newPage, false)
            setPage(newPage)
        }
    }

    const onShareLinkClick = (contactList) => {
        Analytics.logEvent("share_contact_list", {contact_list_id: contactList.id})
        Share.share({ url : getContactListShareLink(contactList)})
    }

    function renderContactList(contactList) { 
        return <Pressable mt={4} mb={4} onPress={() => props.navigation.push("ContactsDraft", {contactList: contactList})}>
            <Heading size="lg" fontWeight={"semibold"}>{contactList.name}</Heading>
            {contactList.id !== -1 && <HStack mt={2}>
                <Text color={"gray.600"}>{contactList.member_count} people</Text>
            </HStack>}

            <HStack mt={2} justifyContent={"space-between"}>
                <Pressable style={{marginRight: 4}} variant={"link"} onPress={() => props.navigation.push("ContactsDraft", {contactList: contactList})}><Text  color={"blue.600"}>Manage</Text></Pressable>
                
                {contactList.joinable && <Pressable onPress={() => onShareLinkClick(contactList)}>
                    <Text underline color={"gray.700"}>Share subscribe link</Text>
                </Pressable>}
            </HStack>
        </Pressable>
    }

    const handleSignUpPress = () => {
        // TODO: Log to mixpanel
        props.navigation.navigate("Signup")
    }

    const upgradePlanPress = () => {
        props.navigation.navigate("Plans")
    }

    return <Layout>
        <>
            {!contacts && <Spinner mt={3}/>}
            {contacts && contacts.length == 0 && <View mt={4} mb={2}>
                <Heading size="sm" mb={1}>Create your first contact list</Heading>
                <Text color={"gray.600"} mb={2} mt={1} fontSize={"xs"}>Contact lists are groups of people you can send reminders to. Lists also come with a sign up link that others can use to join.</Text>
                <HStack space={4} alignItems={"center"}>
                    <Button variant="ghost" pl={0} pt={0} pb={0} onPress={() => props.navigation.push("ContactsDraft")}>Create contact list</Button>
                    {config.contact_video_url && <Pressable onPress={() => openWebUrl(config.contact_video_url, true)}><Text fontSize={"sm"} color={"gray.600"} underline>Watch</Text></Pressable>}
                </HStack>
                </View>}
            {contacts && <FlatList refreshing={refreshLoading} onRefresh={refreshContacts} w="100%" data={[allContact, ...contacts]} renderItem={(item) => renderContactList(item.item) } 
                ItemSeparatorComponent={Divider}
                onEndReachedThreshold={0.1}
                onEndReached={handleOnEndReached}
                ListFooterComponent={loadingMore && <Spinner mt={3}/>}
        />}
        </>
    </Layout>
}