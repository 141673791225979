import { Heading, Button, FlatList, View, HStack, Text, Divider, Pressable } from "native-base";
import Layout from "../components/Layout";
import { useEffect, useLayoutEffect, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from "moment-timezone";
import SimpleActionSheet from "../components/SimpleActionSheet";
import DateTimePicker from '@react-native-community/datetimepicker';
import { Alert, Platform } from "react-native";
import SimpleDateTimePicker from "../components/SimpleDateTimePicker";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { LoadingModal } from "../components/LoadingModal";
import ConfirmModal from "../components/ConfirmModal";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { ImagePickerAsset } from "expo-image-picker";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import Analytics from "../utils/AnalyticsManager";
import { captureSentryException, handleRequestError, uploadImageAsync } from "../utils";
import ReminderCreatedModal from "../components/ReminderCreatedModal";
import { hasSku } from "../utils/AccessUtil";
import * as Sentry from 'sentry-expo';
import SimpleModal from "../components/SimpleModal";


export default function ReminderSettingScreen(props) {
    const reminder = props.route.params?.reminder;
    const reminderId = props.route.params?.reminderId
    const reminderImage = props.route.params?.image
    const storage = getStorage();

    const user = useSelector((state: any) => state.user)
    const userAccessJson = useSelector((state: any) => state.userAccessJson)

    const isMigratedUser = user?.revenuecat_user_id != user.id

    const reminderApi = new GenericViewSetAPI("reminder")

    const [saving, setSaving] = useState(false)
    const [activeActionSheet, setActiveActionSheet] = useState(null)
    const [frequency, setFrequency] = useState(reminder?.frequency ?? "one-off")
    const [dateType, setDateType] = useState(reminder?.scheduled_at ? null : "Now")
    const [date, setDate] = useState(reminder?.scheduled_at ? moment(reminder?.scheduled_at).toDate() : null)
    const [expiresAt, setExpiresAt] = useState(reminder?.expires_at ? moment(reminder?.expires_at).toDate() : null)
    const [isTemplate, setIsTemplate] = useState(reminder?.is_template ?? false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [conversational, setConversational] = useState(reminder?.conversational ?? false)
    const [sendWithPhoneNumber, setSendWithPhoneNumber] = useState(reminder?.message_type.includes("text") ? reminder?.uses_personal_number ?? true : false)
    const [datePickerType, setDatePickerType] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timeZone, setTimeZone] = useState(reminder?.timezone ?? moment.tz.guess())
    const [showReminderCreatedModal, setShowReminderCreatedModal] = useState(false)
    const [createdReminder, setCreatedReminder] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const [acknowledged, setAcknowledged] = useState(false);

    useEffect(() => {
        AsyncStorage.getItem('@ReminderAcknowledged').then(value => {
            if (value === 'true') setAcknowledged(true);
        });
    }, []);

    useEffect(() => {
        Analytics.logEvent("view_page", { page: "ReminderSettingScreen" })
        if (reminder?.message_type?.includes("text")) {
            if (reminder?.uses_personal_number == null || reminder?.uses_personal_number == undefined )  {
                console.log("IS MIGRATED USER", isMigratedUser)
                console.log(reminder?.uses_personal_number)
                if (isMigratedUser) { 
                    console.log("Seting to not use personal number")
                    setSendWithPhoneNumber(false)
                }
            }
        }
    }, [])

    useLayoutEffect(() => {
        props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={async () => {
            if (dateType !== 'Now' && sendWithPhoneNumber && !reminderId && !acknowledged) {
                Alert.alert(
                    'Reminder Schedule',
                    'Reminders scheduled to be sent in the future from your personal phone number require you to open the app at the scheduled time to begin the sending process. This is a limitation of Apple devices. If you would like the reminders to be sent without any involvement, please select "Reminderbase number" instead.',
                    [
                        { text: 'I understand', onPress: () => {
                            AsyncStorage.setItem('@ReminderAcknowledged', 'true').then(() => {
                                setAcknowledged(true);
                                setShowConfirmationModal(true)
                            });
                        } }
                    ],
                    { cancelable: false }
                );
            } else {
                setShowConfirmationModal(true)
            }
        }}
        >{reminderId ? "Done" : "Create"}</Button>})
    })

    const handleFinish = async () => {
        // Alert.alert('FINISHING');
        setSaving(true)
        console.log(reminder)
        const reminderBody = {
            ...reminder,
            frequency: frequency,
            scheduled_at: dateType == "Now" ? null : moment(date).utc().format(),
            dateType: dateType,
            conversational: conversational,
            timezone: timeZone,
            expires_at: expiresAt ? moment(expiresAt).utc().format() : null,
            is_template: isTemplate,
            uses_personal_number: sendWithPhoneNumber,
        }

        console.log("CREATING/UPDATING REMINDERR")
        if (reminderImage) {
            const url = await uploadReminderImage(reminderImage)
            Analytics.logEvent("image_uploaded", { reminder: reminderId });
            console.log(`Url for reminder image is ${url}`)
            if (!url) {
                Alert.alert("Image upload failed", "Sorry, it looks like we couldn't upload your image. Please try again later.")
                return
            } else {
                reminderBody.image_url = url
            }
        }

        console.log(JSON.stringify(reminderBody))
        var success = false;
        var reminderId = null
        let updatedReminder = null;
        let reminderCreated = false;
        if (reminder?.id) {
            const response = await reminderApi.update(reminder?.id, reminderBody)
            console.log(response)
            if (response && !response.error) {
                Alert.alert("Reminder updated", "Your reminder has been updated successfully");
                success = true
                updatedReminder = response
            } else {
                handleRequestError(response, props, true, "There was an error updating your reminder. Please try again later.")
            }
            Analytics.logEvent("reminder_updated", { reminder: reminder?.id })
            reminderId = reminder?.id
        } else {
            const response = await reminderApi.create(reminderBody)
            console.log(response)
            if (response && !response.error) {
                success = true
                reminderId = response.id
                updatedReminder = response
                reminderCreated = true;
            } else {
                handleRequestError(response, props, true, "There was an error creating your reminder. Please try again later.")
            }
        }

        if (success) {
            //TODO: Maybe prevent send when no contacts are selected -- also handle when it's an OLD reminder?? Do we really want to send to remidner sending or other stuff.
            if (sendWithPhoneNumber && dateType == "Now") {
                props.navigation.push("ReminderSending", { contacts: reminder?.contacts, reminder: updatedReminder, image: reminderImage, created: reminderCreated })
            } else {
                // If reminder was an update -- go back to reminders, else wait until they hit done on created reminder modal.
                if (reminder?.id) {
                    props.navigation.navigate("Reminders", { createdReminderId: reminder?.id })
                } else {
                    if (reminderCreated) {
                        setCreatedReminder(updatedReminder)
                        setShowReminderCreatedModal(true)
                    }
                }
            }
        }

        setSaving(false);
    }

    const getSettingCell = (label, value, onClick = null, style = null) => {
        return <Pressable onPress={onClick}><HStack mb={3} mt={2} justifyContent={"space-between"}>
            <Text style={style}>{label}</Text>
            <Text style={style}>{value}</Text>
        </HStack>
            <Divider />
        </Pressable>
    }

    const getDefaultExpiresAt = () => {
        if (frequency != null && frequency != "One-off") {
            return "Never"
        } else {
            return "After sending"
        }
    }

    const uploadReminderImage = async (file: ImagePickerAsset) => {
        try {
            return await uploadImageAsync(file, user.id, storage)
        } catch (e) {
            captureSentryException(e)
            return null
        }
    }

    const deleteReminder = async () => {
        setLoading(true)
        const response = await reminderApi.delete(reminder?.id)
        if (response && !response.error) {
            Alert.alert("Reminder deleted", "Your reminder has been deleted successfully");
            props.navigation.navigate("Reminders")
        } else {
            Alert.alert("Error", "There was an error deleting your reminder. Please try again later.")
        }
        setLoading(false)
    }


    const handleCreatedReminderModalClose = () => {
        setShowReminderCreatedModal(false)
        props.navigation.navigate("Reminders", { createdReminderId: createdReminder?.id })
    }

    const handleConversationSet = () => {
        if (hasSku("conversations", userAccessJson)) {
            setConversational(!conversational)
        } else {
            props.navigation.navigate("Plans", { feature: "conversations" })
        }
    }


    const goToTimezoneSelect = () => {
        props.navigation.push("SelectList", {
            title: "Select time zone",
            allowSearch: true,
            searchFilter: (qry) => moment.tz.names().filter((tz) => tz.toLowerCase().includes(qry.toLowerCase())),
            items: moment.tz.names(),
            renderItem: (item) => <Text mt={2} mb={2} fontSize="lg">{item}</Text>,
            onSelect: (tz) => setTimeZone(tz)
        },
        )
    }

    const handleConfirmReminderCreation = () => {
        setShowConfirmationModal(false)
        handleFinish()
    }

    const renderSetting = (item) => {
        if (item === "a") {
            return <View>
                <Heading mb={3}>Reminder settings</Heading>
                {reminder?.message_type?.includes("text") && getSettingCell("Send text with", sendWithPhoneNumber ? "Personal phone number" : "Reminderbase number", () => setActiveActionSheet("phone_type"))}
                {getSettingCell("Frequency", frequency ?? "One-off", () => setActiveActionSheet("frequency"))}
                {getSettingCell("When", dateType ?? moment(date).format('lll'), () => setActiveActionSheet("when"))}
                {getSettingCell("Timezone", timeZone, () => goToTimezoneSelect())}
                {getSettingCell("Expires at", expiresAt ? moment(expiresAt).format('lll') : getDefaultExpiresAt(), () => frequency != "One-off" && setActiveActionSheet("expire"))}
                {getSettingCell("Save as template", isTemplate ? "Yes" : "No", () => setActiveActionSheet("template"))}
                {!user?.custom_number && reminder?.message_type.includes("text") && getSettingCell("Allow responses", conversational ? "Yes" : "No", () => handleConversationSet())}
                <View mt={4}>
                    {reminder?.id && getSettingCell("Delete reminder", "", () => setDeleteModalOpen(true), { color: "red" })}
                </View>
            </View>
        }
    }

    return <Layout>
        <FlatList mt={3} w="100%" data={["a", "b"]} renderItem={(item) => renderSetting(item.item)} />
        <SimpleActionSheet isOpen={activeActionSheet == "frequency"} onClose={() => setActiveActionSheet(null)}
            items={
                [{ label: "One-off", value: 'one-off', text: "Sent only once" }, 
                { label: "Daily", value: "daily" }, 
                { label: "Weekly", value: "weekly" },
                { label: "Bi-weekly", value: "biweekly", text: "Every 2 weeks"},
                { label: "Monthly", value: "monthly" },
                { label: "Quarterly", value: "quarterly", text: "Every 3 months"},
                { label: "Yearly", value: "yearly" }]
            } onSelect={(item) => setFrequency(item.value)} />

        <SimpleActionSheet isOpen={activeActionSheet == "when"} onClose={() => setActiveActionSheet(null)}
            items={
                [{ label: "Now", value: 'now', text: "Sends the reminder immediately" }, { label: "Specific time", value: "later", text: "Sends the reminder at a later time" }]
            }
            onSelect={(item) => {
                if (item.value == "later") {
                    setDatePickerType("when")
                } else if (item.value == "now") {
                    setDateType("Now")
                }
            }} />

        <ConfirmModal size="lg" isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} headerTitle="Confirm reminder" text="" confirmLabel="Save" onConfirm={() => handleConfirmReminderCreation()} >
            <Text fontSize="md">Are you sure you want to continue with this reminder?</Text>
            <Text mt={2} fontSize="xs">Title</Text>
            <Text fontSize="md" fontWeight={"bold"}>{reminder?.title}</Text>
            <Text mt={2} fontSize="xs">Message</Text>
            <Text fontSize="md" numberOfLines={2}>{reminder?.message}</Text>
            <Text mt={2} fontSize="xs">Time</Text>
            <Text fontSize="md">{dateType ?? moment(date).format('lll')}</Text>
            {reminder?.message_type?.includes("text") &&  <View  mt={2}><Text fontSize="xs">Send text with</Text>
            <Text  fontSize="md">{sendWithPhoneNumber ? "Personal phone number" : "Reminderbase number"}</Text></View>}
        </ConfirmModal>

        <SimpleActionSheet isOpen={activeActionSheet == "expire"} onClose={() => setActiveActionSheet(null)}
            items={
                [{ label: getDefaultExpiresAt(), value: 'default' }, { label: "Specific time", value: "later", text: "The reminder should expire at a specific time" }]
            }
            onSelect={(item) => {
                if (item.value == "later") {
                    setDatePickerType("expire")
                } else {
                    setExpiresAt(null)
                }
            }} />

        <SimpleActionSheet isOpen={activeActionSheet == "template"} onClose={() => setActiveActionSheet(null)}
            items={
                [{ label: "No", value: false, text: "Don't save as template" }, { label: "Yes", value: true, text: "Save this reminder as a template" }]
            }
            onSelect={(item) => setIsTemplate(item.value)} />


        <SimpleActionSheet isOpen={activeActionSheet == "phone_type"} onClose={() => setActiveActionSheet(null)}
            items={
                [{ label: "Personal phone number", value: true, text: "Uses your personal phone number to send reminder. Please note this means that you must manually approve of each text." }, { label: "Reminderbase phone number", value: false, text: "Allows Reminderbase to send the reminder on your behalf. This means that you don't have to manually approve of each text." }]
            }
            onSelect={(item) => setSendWithPhoneNumber(item.value)} />

        <ConfirmModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={() => deleteReminder()} headerTitle="Delete reminder" text="Are you sure you want to delete this reminder?" confirmLabel="Delete" confirmScheme="danger" />

        {datePickerType && <SimpleDateTimePicker date={datePickerType == "when" ? date : expiresAt} onDone={(date) => {
            setDatePickerType(null)
            if (date) {
                datePickerType == "when" ? setDate(date) : setExpiresAt(date)
                datePickerType == "when" ? setDateType(null) : null
            }
        }} />}

        <ReminderCreatedModal isOpen={showReminderCreatedModal} reminder={createdReminder} onClose={() => handleCreatedReminderModalClose()} showNote={reminder?.message_type?.includes("text")} underReview={reminder?.under_review} />

        <LoadingModal visible={saving ?? loading} />
    </Layout>
} 