import GenericViewSetAPI from "../api/GenericViewSetAPI";



export async function canAccessFeature(featureSku) {
    const accessResp = await getAccessFeatureResp(featureSku)
    return accessResp?.has_access
}

export async function getAccessFeatureResp(featureSku) { 
    const userApi = new GenericViewSetAPI("user")
    return await userApi.nonStandard("GET", "has_access", null, { sku: featureSku })
}

export function hasSku(sku, userAccessJson) { 
    console.log("userAccessJson", userAccessJson)
    const skus = userAccessJson?.skus
    if (skus) {
        return skus.includes(sku)
    }
    return false
}