import { FlatList, Heading, View, Text, Button, HStack, Pressable, Divider, Spinner } from "native-base";

import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Platform, Alert, Linking } from 'react-native';
import * as Calendar from 'expo-calendar';
import Layout from "../components/Layout";
import moment from "moment-timezone";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import Analytics from "../utils/AnalyticsManager";
import SearchBar from "../components/SearchBar";
import GenericViewSetAPI from "../api/GenericViewSetAPI";

export default function CalendarScreen(props) {

    const reminderApi = new GenericViewSetAPI("reminder")

    const [calendars, setCalendars] = React.useState([]);
    const [eventsBank, setEventsBank] = useState([])
    const [events, setEvents] = useState([])
    const [searchQry, setSearchQuery] = useState(null)
    const [calendarReminders, setCalendarReminders] = useState([])
    const [calendarIdToReminderMap, setCalendarIdToReminderMap] = useState({})
    const [loading, setLoading] = useState(false)
    const [needsCalendarAccess, setNeedsCalendarAccess] = useState(false)
    const [refreshing, setRefreshing] = useState(false)

    const startDate = new Date();
    const endDate = new Date(new Date().setDate(new Date().getDate()+120))

    useFocusEffect(useCallback(() => {
      Analytics.logEvent("view_page", {page: "CalendarScreen"})
      fetchCalendarReminders()
    }, []))

    const fetchCalendarReminders = async() => {
      const reminderCalendarResp = await reminderApi.query({start: startDate.toISOString(), end: endDate.toISOString(), is_calendar: true})
      if (reminderCalendarResp && !reminderCalendarResp.error){
        console.log("Calendar reminders", reminderCalendarResp)
        setCalendarReminders(reminderCalendarResp.results)
        const map = {}
        for (var reminder of reminderCalendarResp.results){
          map[reminder.calendar_event_id] = reminder
        }
        console.log(map)
        setCalendarIdToReminderMap(map)
      }
    }

    useEffect(() => {
        fetchCalendars();
    }, []);


    const fetchCalendars = async() => {
        setLoading(true)
        const { status } = await Calendar.requestCalendarPermissionsAsync();
        if (status === 'granted') {
          const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
          console.log('Here are all your calendars:');
          console.log({ calendars });
        } else { 
          setNeedsCalendarAccess(true)
          setLoading(false)
          // Alert.alert("Please enable calendar access in settings")
          return;
        }
        const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
        console.log('Here are all your calendars:');
        // console.log({ calendars });
        // setCalendars(calendars);
        const calendarIds = calendars.map((calendar) => calendar.id);
        // console.log("Calendar ids")
        // console.log(calendarIds))
        const events = await Calendar.getEventsAsync(calendarIds, startDate, endDate);
        setEvents(events)
        setEventsBank(events)
        setLoading(false)
    }


    function getReminder(event) {
      return calendarIdToReminderMap[event.id]
    }

    const handleTextChange = (text) => {
      setEvents(eventsBank.filter(e => e.title.includes(text)))
      if (text === ""){
        setEvents(eventsBank)
      }
    } 

    const handleRefresh = () => {
      fetchCalendars()
      fetchCalendarReminders()
    }


    function renderCalendarItem(event) {
        if (event === "search"){
          if (loading) {
            return <Spinner mt={3} />
          }
          return <>
                <SearchBar hideSearchButton onChangeText={(text) => handleTextChange(text) }/>
                 {needsCalendarAccess && <HStack mt={4}>
                  <Button onPress={() => Linking.openSettings()}>Enable calendar access to create reminders from your calendar</Button>
                  </HStack>
                  }
          </>
                  
          
        }
        // console.log(event)
        const reminder = getReminder(event)
        return <View mt={2} mb={2}>
            <Heading size="md" mb={1}>{event.title}</Heading>
            <Text>{moment(event.startDate).format('lll')}</Text>
            {event.notes && !reminder && <Text mt={1} fontSize="xs" color="gray.600">{event.notes}</Text>}

            {reminder && <View mt={2}>
                <Text fontSize="xs" color="gray.600">Reminder scheduled</Text>
                <HStack mt={3}>
                    <Button variant="subtle" colorScheme={"coolGray"} rounded="xl" onPress={() => props.navigation.push("Draft", {reminder: reminder})}>Edit reminder</Button>
                </HStack>
                {/* <Pressable mt={1}>
                    <Text underline color="blue.400">Edit reminder</Text>
                </Pressable> */}
            </View>}

            <HStack mt={2} mb={1}>
                {!reminder && <Button variant="subtle" size="sm" onPress={() => props.navigation.push("Draft", {title: event.title, start: event.startDate, end: event.endDate, calendarId: event.id })}>Create reminder</Button>}
        </HStack>
        </View>
    }
  return (
    <Layout>
      {/* <Text>Calendar Module Example</Text> */}
      {/* {loading && <Spinner mt={4} />} */}
      <FlatList w="100%"  showsVerticalScrollIndicator={false} data={["search", ...events]} renderItem={(item) => renderCalendarItem(item.item)} 
        ItemSeparatorComponent={Divider}
        onRefresh={handleRefresh}
        refreshing={refreshing}
        

      />
      {/* <Button title="Create a new calendar" onPress={createCalendar} /> */}
    </Layout>
  );
}

async function getDefaultCalendarSource() {
  const defaultCalendar = await Calendar.getDefaultCalendarAsync();
  return defaultCalendar.source;
}

async function createCalendar() {
  const defaultCalendarSource =
    Platform.OS === 'ios'
      ? await getDefaultCalendarSource()
      : { isLocalAccount: true, name: 'Expo Calendar' };
  const newCalendarID = await Calendar.createCalendarAsync({
    title: 'Expo Calendar',
    color: 'blue',
    entityType: Calendar.EntityTypes.EVENT,
    // sourceId: defaultCalendarSource.id,
    // source: defaultCalendarSource,
    name: 'internalCalendarName',
    ownerAccount: 'personal',
    accessLevel: Calendar.CalendarAccessLevel.OWNER,
  });
  console.log(`Your new calendar ID is: ${newCalendarID}`);
}

// const styles = StyleSheet.create({ ... }); 