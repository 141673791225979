import { Heading, HStack, Modal, Spinner } from "native-base";
import React from "react";

export function LoadingModal(props) {
    return <Modal isOpen={props.visible ?? props.loading ?? props.isOpen ?? false} size="lg">
        <Modal.Content>
            <Modal.Body>
                <HStack justifyContent={"center"} alignItems="center" space={1}>
                    <Spinner accessibilityLabel="Loading..." size="lg" />
                    <Heading color="primary.500" fontSize="md">
                        Loading
                    </Heading>
                </HStack>
            </Modal.Body>
        </Modal.Content>
    </Modal>
}