import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { showAlert } from "../utils";
import { View, Text, Button, HStack } from "native-base";


export default function PublicContactAdminScreen(props) { 

    const contactUuid = props.route.params?.id
    const contactApi = new GenericViewSetAPI("contact")

    const [contact, setContact] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchContact = async() => {
        const contactResp = await contactApi.getById(contactUuid, {expansions: ["user"]})
        console.log(contactResp)
        if (contactResp && !contactResp.error) { 
            setContact(contactResp)
        } else { 
            showAlert("Sorry, we couldn't find that contact")
        }
    }

    const respondToContact = async(status) => { 
        setLoading(true)
        const contactResp = await contactApi.update(contact.id, {status: status})
        if (contactResp && !contactResp.error) { 
            setContact(contactResp)
        } else { 
            showAlert("Sorry, we couldn't find that contact")
        }
        setLoading(false)
    }

    const getContactBody = () => {
        if (contact.status == "pending") {
            return <View>
                <Text>Would you like to recieve reminders from {contact.user.name}</Text>
                <HStack>
                    <Button isLoading={loading} onPress={() => {
                        respondToContact("accepted")
                    }}>Yes</Button>
                    <Button isLoading={loading} onPress={() => {
                        respondToContact("unsubscribed")
                    }}>No</Button>
                </HStack>
            </View>
        } else if (contact.status == "accepted") {
            return <View>
                <Text>You are now recieving reminders from {contact.user.name}</Text>
                <Button isLoading={loading} onPress={() => respondToContact("unsubscribed")}>Unsubscribe</Button>
            </View>
        } else if (contact.status == "unsubscribed") {
            return <View>
                <Text>You are unsubscribed from recieving reminders from {contact.user.name}</Text>
                <Button isLoading={loading} onPress={() => respondToContact("accepted")}>Subscribe</Button>
            </View>
        }
    }

    useEffect(() => {
        fetchContact()
    }, [])

    return <Layout>

        {contact && <View>
                {getContactBody()}
            </View>}
    </Layout>
}