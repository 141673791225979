
// import { Mixpanel } from "mixpanel-react-native";
import { Platform } from "react-native";

class AnalyticsManager { 
    static instance = null;
    private mixpanelNative: any = null;
    private mixpanelWeb: any = null;

    constructor() {
        if (!AnalyticsManager.instance) {
            AnalyticsManager.instance = this;
        }
        return AnalyticsManager.instance;
    }

    private getMixpanel() {
        if (this.mixpanelNative) {
            return this.mixpanelNative;
        } else if (this.mixpanelWeb) { 
            return this.mixpanelWeb;
        }
        return null;
    }
    
    public async initialize() {
        console.log("INITIALIZING MIXPANELL WTFFF")
        if (this.getMixpanel()) {
            console.log("MIX PANEL NON-NULL?? RETURNING")
            return;
        }
        const trackAutomaticEvents = false
        if (Platform.OS == "web") {
            return
        } else { 
            const Mixpanel = (await import("mixpanel-react-native")).Mixpanel;
            const mixPanel = new Mixpanel("a7ead51378a85c6421f110735bce08c6", trackAutomaticEvents);
            await mixPanel.init();
            this.mixpanelNative = mixPanel;
            console.log("MIXPANEL HAS BEEN CREATED")
        }
    }

    logEvent(eventName, properties={}) { 
        if (Platform.OS === "web")  {
            return;
        }
        this.getMixpanel().track(eventName, properties)
    }

    async identify(userId) { 
        if (Platform.OS === "web")  {
            return;
        }
        return await this.getMixpanel().identify(`${userId}`)
    }

    setUserProperties(properties) {
        // mixpanel
        if (Platform.OS == "web") {
            return
        } else { 
            this.getMixpanel().getPeople().set(properties)
        }
    }

    reset() { 
        if (Platform.OS === "web")  {
            return;
        }
        this.getMixpanel().reset()
    }
}

const Analytics = new AnalyticsManager();

export default Analytics;