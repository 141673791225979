import { GoogleAuthProvider } from "firebase/auth";
import { Platform } from "react-native";
import { GoogleSignin } from "@react-native-google-signin/google-signin";

class GoogleManager {
    static instance: any;
    private googleLogin: any = null;

    constructor() { 
        if (!GoogleManager.instance) { 
            GoogleManager.instance = this;
        }
        return GoogleManager.instance;
    }

    public async initialize() { 
        if (this.googleLogin) { 
            return;
        }
        if (Platform.OS == "web") { 

        } else { 
            // console.log("Google sign in", GoogleSignin)
            const imported = await import('@react-native-google-signin/google-signin')
            
            console.log(imported)
            const module = await (await import('@react-native-google-signin/google-signin')).GoogleSignin
            console.log(module)
            this.googleLogin = module
            this.googleLogin.configure();
        }
    }

    public handleGoogleSignIn = async () => {
        await this.initialize();
        // TODO: Check if credential already exists and if so just use it. 
        

        await this.googleLogin.hasPlayServices({ showPlayServicesUpdateDialog: true });

        // Get the user ID token
        try { 
        const { idToken } = await this.googleLogin.signIn();

        const googleCredential = GoogleAuthProvider.credential(idToken);
        console.log(googleCredential)
        return googleCredential;
        } catch (e) { 
            console.error(e)
        }
        // setGoogleCredential(googleCredential);

        // try { 
        //     const userCredential = await signInWithCredential(getAuth(), googleCredential);
        //     setUserCredential(userCredential);
        //     const newToken = await getTokenFromUserCredential(userCredential, true);
        // } catch (e) {
        //     handleFirebaseLoginError(e);
        // }
    }
}


const googleManager = new GoogleManager();
export default googleManager;

