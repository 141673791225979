/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { Platform } from 'react-native';
// import CountriesVisited from '../screens/CountriesVisited';
// import PersonaQuiz from '../screens/PersonaQuiz';

const linking: LinkingOptions<any> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Signup: 'login',
      ListRegister: 'list/:slug',
      UserListRegister: 'user/list/:user_id',
      Respond: 'respond/:slug',
      PublicContactAdmin: 'contact/:id',
      // NotFound: '*',
    },
  },
};

if (Platform.OS === "web") {
  Object.assign(linking.config.screens, {})
  linking.config.screens
} else {
  Object.assign(linking.config.screens, {})
}

export default linking;