import { useSelector } from "react-redux";
import Layout from "../components/Layout";
import { useCallback, useEffect, useState } from "react";
import { LoadingModal } from "../components/LoadingModal";
import { useFocusEffect } from "@react-navigation/native";


export default function Loading(props) { 

    const config = useSelector((state: any) => state.config)
    const user = useSelector((state: any) => state.user)
    const [loading, setLoading] = useState(true)

    useFocusEffect(useCallback(() => {
        // console.log("sign up google login", GoogleSignin)
        if (user) { 
            props.navigation.navigate("Root")
        } else { 
            props.navigation.navigate("Signup")
        }
        setLoading(false)
    }, [user, config]))

    return <Layout>
        <LoadingModal visible={loading} />
    </Layout>
}