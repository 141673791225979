import { Actionsheet, Text } from "native-base";


export default function SimpleActionSheet(props) {

    const onSelect = (item) => {
        if (item.onSelect) { 
            item.onSelect()
        } else { 
            props.onSelect(item)
            props.onClose()
        }
    }

    return <Actionsheet isOpen={props.isOpen} onClose={props.onClose}>
         <Actionsheet.Content>
                {props.items && !props.onRender && props.items.map(item => <Actionsheet.Item onPress={() => onSelect(item)}>
                    <Text fontSize="md">{item.label}</Text>
                    {item.text && <Text fontSize="sm" color="coolGray.600">{item.text}</Text>}
                </Actionsheet.Item>)}
                {props.items && props.onRender && props.items.map(item => props.onRender(item))}
        </Actionsheet.Content>
        </Actionsheet>

}