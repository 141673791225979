import { Badge, Button, Divider, FlatList, HStack, Icon, Input, Pressable, Spinner, Text, View } from "native-base";
import Layout from "../components/Layout";
import { useEffect, useLayoutEffect, useState } from "react";
import * as Contacts from 'expo-contacts';
import { Feather } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons'; 
import Placeholder from "../Constants/Placeholder";
import { Alert } from "react-native";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { getPhoneBookContacts, showAlert } from "../utils";
import Data from "../Constants/Data";

export default function MyContactsScreen(props) { 

    const isPhoneOnly = props.route.params?.isPhoneOnly ?? false

    const contactApi = new GenericViewSetAPI("contact")

    const [page, setPage] = useState(1)
    const [finished, setFinished] = useState(false)
    const [searchQuery, setSearchQuery] = useState(null)
    const [contactsBank, setContactsBank] = useState([]);
    const [serverContactsResp, setServerContactsResp] = useState(null)
    const [serverContacts, setServerContacts] = useState(props.route.params?.serverContacts ?? [])
    const [contacts, setContacts] = useState([]);
    const [selectedMap, setSelectedMap] = useState(props.route.params?.selectedMap ?? {})
    const [canGivePhonePermission, setCanGivePhonePermission] = useState(true);
    const [contactType , setContactType] = useState("existing")
    const [loadingMore, setLoadingMore] = useState(false)

    useEffect(() => {
        if (!isPhoneOnly) { 
            fetchServerContacts()
        }
        checkStatusAndMaybeGetContacts([]);

    }, [])

    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button variant="ghost" onPress={() => onFinish()}>Done</Button>
        })
    })


    const fetchServerContacts = async(page=1, overwrite=false) => { 
        let query: any = {}
        if (searchQuery) { 
            query.name = searchQuery
        }
        query.page = page
        query.page_size = Data.pageSize
        // console.error(query)
        const serverContactsResp = await contactApi.query(query)
        console.log('server contacts resp', serverContactsResp)
        if (serverContactsResp && !serverContactsResp.error) { 
            setServerContactsResp(serverContactsResp)
            if (overwrite) {
                setServerContacts(serverContactsResp.results)
                if (page == 1) { 
                    setPage(1)
                }
            } else { 
                setServerContacts([...serverContacts, ...serverContactsResp.results])
            }
            // const existingContacts = [...serverContactsResp.results, ...contacts]
            // setContacts(existingContacts)
        }
    }

    const onFinish = () => {
        if (props.route.params?.onSelectedMapChange) {
            props.route.params.onSelectedMapChange(selectedMap)
        }

        props.navigation.goBack()
    }

    useEffect(() => {
        if (finished) { 
            onFinish()
        }
    }, [finished])

    useEffect(() => {
        if (searchQuery) { 
                const filteredContacts = contactsBank.filter(contact => {
                    return contact.name?.toLowerCase().includes(searchQuery.toLowerCase()) || contact.phone?.includes(searchQuery)
                })
                //TODO: Add filtered server contacts too.
                setContacts(filteredContacts)
        } else {
            if (contactType === "existing") { 
                fetchServerContacts(1, true)
            } else { 
                setContacts(contactsBank)
            }
        }
    }, [searchQuery])


    const checkStatusAndMaybeGetContacts = async (startContacts) => {
        const { status } = await Contacts.getPermissionsAsync();
        console.log(status)
        if (status == 'granted') {
            getContacts(startContacts)
        } else {
            setContacts(startContacts)
            setCanGivePhonePermission(true);
        }
    }


    const getContacts = async (startContacts) => {
        const { status } = await Contacts.requestPermissionsAsync();
        console.log(status)
        if (status === 'granted') {
            setCanGivePhonePermission(false);
            const newContacts = await getPhoneBookContacts()
          
            const currentContacts = [...startContacts, ...newContacts]
            console.log("RECIEVED CONTACTS FROM PHONE")
            console.log(newContacts)
            setContactsBank(newContacts)
            console.log("EXISITNG CONTACTS")
            console.log(contacts)

            setContacts(currentContacts)
        } else {
            setCanGivePhonePermission(true);
            if (status == "denied") { 
                showAlert("Please give Reminderbase access to your calendar by going to your settings")
            }
        }
    }

    useEffect(() => {
        if (contactType === "existing") { 
            fetchServerContacts(1, true)
        } else {
            return
        }

    }, [contactType])

    const handleSearch = () => {
        if (contactType === "existing") { 
            fetchServerContacts(1, true)
        }
    }

    const handleOnPress = (contact) => {
        const existingMap = {...selectedMap}
        const convertedContact = {
            systemId: contact.id,
            id: contactType == "existing" ? contact.id : null,
            name: contact.name,
            mobile: contact.mobile,
            email: contact.email,
            image: contact.image,
            device_contact_id: contact.id
        }
        if (existingMap[contact.id]) { 
            delete existingMap[contact.id]
        } else { 
            existingMap[contact.id] = convertedContact
        }
        setSelectedMap(existingMap)
        // props.route.params?.onSelectedMapChange ?? props.route.params?.onSelectedMapChange(existingMap)
    }

    const handleLoadMore = async () => {
        if (contactType === "existing") { 
            const nextPage = serverContactsResp.next
            if (nextPage) { 
                setLoadingMore(true)
                const nextPage = page + 1
                setPage(nextPage)
                await fetchServerContacts(nextPage, false)
                setLoadingMore(false)
            }
        }
    }

    const renderContact = (item) => {
        const isPhoneContact = item.item.source === "phone"
        return <Pressable onPress={() => handleOnPress(item.item)} mt={2} mb={2}>
            <HStack w="100%">
                <View flex={6}>
                    <Text fontSize="xl">{item.item.name}</Text>
                    <Text color={"coolGray.600"} fontSize={"sm"}>{item.item.mobile}</Text>
                    {item.item.email && <Text color={"coolGray.600"} fontSize={"sm"}>{item.item.email}</Text>}
                    <HStack>
                        <Badge  mt={2} mb={2} colorScheme={isPhoneContact ? "amber" : "primary"}>{isPhoneContact ? "Phone contact" : "Contact list"}</Badge>
                    </HStack>
                </View>
                <View flex={1} justifyContent={"center"}>
                    {selectedMap[item.item.id] && <AntDesign name="checkcircle" size={24} color="green" />}
                </View>
            </HStack>
        </Pressable>
    }

    const renderItem = (item) => {
        if (item === 0 && canGivePhonePermission && contactType === "phone") {
            return <Button rounded="full" onPress={() => getContacts(serverContacts)}>Import phone contacts</Button> 

        }
        if (item == 1) { 
            return <FlatList keyboardShouldPersistTaps="always" showsVerticalScrollIndicator={false} data={contactType == "existing" ? serverContacts : contacts} w="100%" ItemSeparatorComponent={Divider} renderItem={(item: any) => renderContact(item)} />
        }
    }

    return <Layout>

<Input mt={3} size="md" w="100%" variant="filled" placeholder="Search by name" value={searchQuery} onChangeText={(val) => setSearchQuery(val)}
            InputLeftElement={<Icon as={Feather} name="search" ml="2" size="4" />} rightElement={<Button variant={"ghost"} onPress={handleSearch}>Search</Button>} />
        <HStack mt={4} mb={2} space={2} w="100%">
                    <Button rounded={"full"} colorScheme={contactType === "existing" ? "primary" : "light" }  onPress={() => setContactType("existing")}>Existing contacts</Button>
                    <Button rounded={"full"} colorScheme={contactType === "phone" ? "primary" : "light" } onPress={() => setContactType("phone")} >My phone contacts</Button>
            </HStack> 
        <FlatList keyboardShouldPersistTaps="always" data={[0, 1]} w="100%" showsVerticalScrollIndicator={false} renderItem={(item: any) => renderItem(item.item)} 
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.1}
            ListFooterComponent={loadingMore && <Spinner mt={3}/>}
        />
    </Layout>
}