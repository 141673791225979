import { Button, HStack, Heading, Modal, Text } from "native-base";
import SimpleModal from "./SimpleModal";
import { useState } from "react";


export default function InitialReminderCreateModal(props) {

    return <SimpleModal notClosable isOpen={props.isOpen} onClose={() => null} size={props.size} 
    headerTitle={"Notifications to keep you updated"} 
    getFooter={() => <HStack space={2}><Button onPress={props.onConfirm} colorScheme={props.confirmScheme ?? "primary"}>{props.confirmLabel ?? "Notify me"}</Button><Button onPress={props.onCancel} colorScheme={"coolGray"}>No thanks</Button></HStack>}>
        <Text>Turn on notifications to get notified when your reminders are sent, when you get a response or when you get a new subscriber. We won't ever spam you </Text>
    </SimpleModal>
        
}