import { StatusBar } from 'expo-status-bar';
import { NativeBaseProvider, extendTheme } from 'native-base';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { Provider } from 'react-redux'
import Navigation from './navigation';
import { initializeStore, setUser, setConfig, setToken, setUserAccessJson } from './store';
import { initializeFirebase } from './firebaseConfig';
import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getCurrentUser, getSentryReleaseString } from './utils';
import GenericViewSetAPI from './api/GenericViewSetAPI';
import BaseApiConfigProvider from './api/BaseApiConfigProvider';
import GoogleManager from './utils/GoogleManager';
import Analytics from './utils/AnalyticsManager';
import { LoadingModal } from './components/LoadingModal';
import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants'
import Purchases from 'react-native-purchases';
import Data from './Constants/Data';
import Config from './utils/Config';
import Toast, { BaseToast, InfoToast } from 'react-native-toast-message';


Sentry.init({
  dsn: 'https://0f6f03e97b9ecf3228e08f9f01dd9840@o4506165191049216.ingest.sentry.io/4506165192687616',
  enableInExpoDevelopment: true,
  release: getSentryReleaseString(),
  dist: Config.internalVersion,
  environment: BaseApiConfigProvider.getEnvType(),
  debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  integrations: Platform.OS != "web" ? [
    new Sentry.Native.ReactNativeTracing({
      shouldCreateSpanForRequest: url => {
        return !__DEV__ || !url.startsWith(`http://${Constants.expoConfig.hostUri}/logs`);
      },
    })
  ] : [] 
});

if (!__DEV__) { 
  console.log = () => null
  console.error = () => null
  console.warn = () => null
}


const toastConfig = {
  info: (props) => (
    <InfoToast
      {...props}
      contentContainerStyle={{ paddingVertical: 10 }}
      text1Style={{
        fontSize: 18,
      }}
      text2Style = {{
        fontSize: 15
      }}
      text2NumberOfLines={2}
    />
  )
}


if (Platform.OS !== "web") { 
  GoogleManager.initialize()
}
const store = initializeStore()
const fireBaseApp = initializeFirebase()


const App = () => {


  const [apiFinished, setApiFinished] = useState(false)

  const statusBar = <StatusBar style={"dark"} />

  const intializePurchases = async (config) => {
    var apiKey = null
    if (Platform.OS === 'ios') {
        apiKey = Data.revenueCatIosKey
    } else if (Platform.OS === 'android') {
        apiKey = Data.revenueCatAndroidKey
    } else if (Platform.OS === 'web') {
        apiKey = Data.revenueCatStripeKey
    }
    if (Platform.OS !== 'web') { 
      Purchases.configure({apiKey: apiKey});
    }
    if (Platform.OS !== 'web') {
      Purchases.setLogLevel(Purchases.LOG_LEVEL.VERBOSE);
    }
  }

  const loadUser = async () => {
    await BaseApiConfigProvider.loadUserEnvPreference()
    const token =  await BaseApiConfigProvider.loadUserToken()
    console.log("MAKING API CALLS")
    const userApi = new GenericViewSetAPI("user")

    const [configResp, featureAccessResp] = await Promise.all([userApi.nonStandard("GET", "config"), userApi.nonStandard("GET", "feature_access")])
    await Analytics.initialize()
    intializePurchases()
    console.log("MIXPANEL FINISHED")
    if (token) { 
      console.log("User token found...fetching user")
      const user = await getCurrentUser(token);
      if (user && !user.error) { 
        store.dispatch(setToken(token))
        store.dispatch(setUser(user))
        Analytics.identify(user.id)
        await Purchases.logIn(user.revenuecat_user_id)
        await Purchases.setAttributes({"user_id": user.id, "email": user.email, "internal_version": Config.internalVersion})
      } else { 
        await BaseApiConfigProvider.removeUserToken();
      }
    } else {
      console.log("No user token found...doing nothing")
    }
    console.log("CONFIG RESPONSE======", configResp)
    if (configResp && !configResp.error) {
      console.log("Config retrieved")
      console.log(configResp)
      store.dispatch(setConfig(configResp))
    }
    console.log()
    if (featureAccessResp && !featureAccessResp.error) {
      console.log("User access is", featureAccessResp)
      store.dispatch(setUserAccessJson(featureAccessResp))
    }

    setApiFinished(true)
  }


  useEffect(() => {
    loadUser()
  }, [])

  const theme = extendTheme({
    colors: {
      // Add new color
      primary: {
        50: '#E3F2F9',
        100: '#C5E4F3',
        200: '#A2D4EC',
        300: '#7AC1E4',
        400: '#47A9DA',
        500: '#0088CC',
        600: '#007AB8',
        700: '#006BA1',
        800: '#005885',
        900: '#003F5E',
      },
      // Redefining only one shade, rest of the color will remain same.
      amber: {
        400: '#d97706',
      }
    }
  });


    return <Provider store={store}>
        <NativeBaseProvider theme={theme}>
          {statusBar}
          {apiFinished && <Navigation colorScheme="light" />}
          {!apiFinished && <LoadingModal visible/>}
        </NativeBaseProvider>
        <Toast config={toastConfig}/>
      </Provider>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default App
