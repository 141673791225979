import { Button, Heading, TextArea, View, Text, Spinner, HStack, Stack, Center, FormControl } from "native-base";
import Layout from "../components/Layout";
import { Image, Linking } from 'react-native'
import { useEffect, useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { showAlert } from "../utils";


export default function RespondScreen(props) {
    const uuid = props.route.params?.slug
    const allowResponse = props.route.params?.ar == "true"

    const [contactLoaded, setContactLoaded] = useState(false)
    const [contact, setContact] = useState(null)
    const [message, setMessage] = useState(null)
    const [messageSent, setMessageSent] = useState(false)
    const [loading, setIsLoading] = useState(false)

    const contactApi = new GenericViewSetAPI("contact")


    useEffect(() => {
        fetchContact()
    }, [])


    const fetchContact = async () => {
        const contactResp = await contactApi.nonStandard("POST", "uuid", { uuid: uuid })
        if (contactResp && !contactResp.error) {
            setContact(contactResp)
        } else {
            showAlert("Sorry, we couldn't find that contact")
        }
        setContactLoaded(true)
    }

    const saveMessage = async () => {
        const body = {
            uuid: uuid,
            message: message
        }
        setIsLoading(true)
        const resp = await contactApi.nonStandard("POST", "respond", body)
        if (resp && !resp.error) {
            setMessageSent(true)
        } else {
            showAlert("Sorry, there was an issue sending your message. Please try again")
        }
        setIsLoading(false)
    }

    const respondToContact = async (status) => {
        setIsLoading(true)
        const contactResp = await contactApi.nonStandard("POST", "status", { uuid: uuid, status: status })
        if (contactResp && !contactResp.error) {
            console.log(contactResp)
            setContact(contactResp)
        } else {
            showAlert("Sorry, we couldn't update that contact")
        }
        setIsLoading(false)
    }


    return <Layout minH="700px">
        <View w="100%" minH="700px" mt={6} >
            <Image source={require("../assets/icon.png")} style={{ width: 100, height: 100, borderRadius: 50, alignSelf: "center" }} />
            {!contactLoaded && <Spinner mt={2} />}
            {contactLoaded && <View>
                {messageSent && <View w="100%">
                    <Heading mt={4} mb={4} textAlign={"center"}>Message sent</Heading>
                </View>
                }
                {!messageSent && (contact.status == "pending" || contact.status == "new") && <View w="100%">
                    <Heading mt={4} mb={4} textAlign={"center"}>Respond to request</Heading>
                    <Text fontSize="md" textAlign={"center"}>Would you like to recieve these reminder messages? {contact.user?.name}</Text>
                    <Stack mt={4} space={2}>
                        <Button variant="subtle" isLoading={loading} onPress={() => {
                            respondToContact("accepted")
                        }}>Accept reminders</Button>
                        <Button colorScheme={"danger"} variant="subtle" isLoading={loading} onPress={() => {
                            respondToContact("unsubscribed")
                        }}>Reject reminders</Button>
                    </Stack>
                </View>}

                {!messageSent && contact.status == "unsubscribed" && <View w="100%">
                    <Heading textAlign={"center"} mt={4}>You are currently unsubscribed and not recieving reminders</Heading>
                    <Button mt={4} variant="subtle" isLoading={loading} onPress={() => respondToContact("accepted")}>Subscribe</Button>
                </View>
                }

                {!messageSent && contact.status == "accepted" && <View w="100%">
                    <Heading mt={4} mb={4} textAlign={"center"}>You can currently subscribed</Heading>
                    {allowResponse && <><FormControl>
                        <FormControl.Label>Send message</FormControl.Label>
                        <TextArea totalLines={10} h={150} autoCompleteType={""} size="lg" placeholder="Enter your message...." onChangeText={(text) => setMessage(text)} />
                    </FormControl>
                    <Stack>
                        <Center>
                            <Button mt={2} size="lg" minW="50%" maxW="500px" onPress={saveMessage} isLoading={loading}>Submit message</Button>
                        </Center>
                    </Stack></>}
                    <Center>
                        <Button mt={2} minW="50%" maxW={"500px"} variant="subtle" isLoading={loading} colorScheme={"danger"} onPress={() => respondToContact("unsubscribed")}>Unsubscribe</Button>
                    </Center>

                </View>}
                <View mt={2} h="100px" mb={20} >
                    <Text mt={4} color="coolGray.600" textAlign={"center"} fontSize={"xs"}>Reminderbase is the best way to help your friends, family and customers remember the things that matter. Create your reminder in 2 minutes</Text>
                    <Button size="sm" onPress={() => Linking.openURL("https://www.reminderbase.co")} variant={"link"}>Create your reminder</Button>
                </View>
            </View>}
        </View>
    </Layout>
}