

import AsyncStorage from "@react-native-async-storage/async-storage"
import GenericViewSetAPI from "../api/GenericViewSetAPI"
import { Alert, Linking, Platform } from "react-native"
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import * as ImagePicker from 'expo-image-picker';
import Constants from "expo-constants";
import * as WebBrowser from 'expo-web-browser';
import Config from "./Config";
import * as Contacts from 'expo-contacts';
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import compareVersions from 'compare-versions'
import { captureException } from "@sentry/react-native";
import * as Sentry from 'sentry-expo';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

export const getContactListShareLink = (contactList) => {
  return `${BaseApiConfigProvider.getWebUrl()}/list/${contactList.id}`
}

export const getToken = async () => {
  return await AsyncStorage.getItem("token")
}

export const openWebUrl = async (url, useBrowser = false) => {
  if (useBrowser) {
    await WebBrowser.openBrowserAsync(url);
  } else {
    Linking.openURL(url)
  }
}

export const getNumberRepresentation = (number) => {
  if (number == -1) {
    return "unlimited"
  } else {
    return `${number}`
  }
}


export function getVersionString() {
  var string = `${Constants.expoConfig.runtimeVersion}-${Config.internalVersion}`
  const extra = Constants.expoConfig.extra
  if (extra && extra.appEnv) {
    if (__DEV__) {
      string += "-dev"
    } else {
      string += `-${extra.appEnv}`;
    }
  }
  return string;
}

export const getCurrentUser = async (token) => {
  if (token) {
    let userApi = new GenericViewSetAPI("user")
    let userResp = await userApi.nonStandard("GET", "me");
    return userResp;
  }
  return null;
}

export const isAccountNeedErrorMessage = (errorMsg) => {
  return errorMsg == "Authentication credentials were not provided."
}

export const getContactList = (reminder) => {
  if (reminder?.contact_list_recipient) {
    return { recipient_id: reminder?.contact_list_recipient, ...reminder.contact_list_recipient.contact_list }
  }
  return null
}

export const getContacts = (reminder) => {
  if (reminder?.contact_recipients) {
    return reminder?.contact_recipients.map(item => { return { recipient_id: item.id, ...item.contact } })
  }
  return null
}

export const pickImage = async () => {
  // No permissions request is necessary for launching the image library
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    aspect: [4, 3],
    quality: 1,
  });

  console.log(result);

  if (!result.canceled) {
    return result.assets[0];
  }

  return null;
};

export async function uploadImageAsync(file, user_id, storage=null) {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob: Blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", file.uri, true);
    xhr.send(null);
  });
  if (!storage) {
    storage = getStorage();
  }

  console.log(file)
  console.log(blob)
  console.log("NOW LOOKING AT FILE REF")
  const fileRef = ref(storage, `/user/${user_id}/` + uuidv4());
  console.log('fileRef', fileRef)

  const uploadTask = uploadBytesResumable(fileRef, blob);

  return await new Promise((resolve, reject) => {
    uploadTask.on('state_changed',
      (snapshot) => {
        console.log("Progress", snapshot)
      },
      (error) => {
        captureSentryException(error)
        console.log("Error", error)
        reject(error)
      },
      async () => {
        try {
          //@ts-ignore
          blob.close()
        } catch (e) {
          captureSentryException(e)
          console.log(e)

        }
        console.log("Complete")
        const downloadUrl = await getDownloadURL(fileRef);
        console.log("Download url", downloadUrl)
        resolve(downloadUrl)
      }
    )
  })
}


export const getToString = (contacts, contactList, recipientCount, localContactsMap = null) => {
  var toStr = ""
  if (contactList) {
    let toStr = `${contactList.name}`;
    const remainingCount = recipientCount - 1
    if (remainingCount <= 0) {
      return toStr
    } else {
      return `${toStr} + ${remainingCount}`
    }
  } else {
    if (recipientCount == 0) {
      return toStr
    } else {
      if (!contacts) {
        return ""
      }
      const firstTwoStr = contacts.slice(0, 2).map(item => getContactName(item, true, localContactsMap)).join(", ")
      const remaining = recipientCount - contacts.length
      if (remaining > 0) {
        return `${firstTwoStr} + ${remaining}`
      } else {
        return `${firstTwoStr}`
      }
    }
  }
}

export function meetsMinimumAppVersion(config) {
  if (config) {
    const minVersion = config.min_internal_version;
    if (minVersion) {
      console.log("Comparing current version to min version of ", minVersion)
      const delta = compareVersions(minVersion, Config.internalVersion);
      if (delta === 1) {
        return false;
      }
    }
  }
  return true
}

function convertFormattedPhoneNumberToDigits(formattedNumber) {
  return formattedNumber.replace(/[^0-9]/g, '');
}


function getFormattedNumber(number) {
  var mobile = number.digits
  if (!mobile && number.number) {
    mobile = convertFormattedPhoneNumberToDigits(number.number)
  }
  if (mobile.length === 10) {
    mobile = `+1${mobile}`
  } else {
    if (!mobile.includes("+")) {
      mobile = `+${mobile}`
    }
  }
  return mobile
}

export function getLimitedText(text, limit) {
  if (text.length > limit) {
    return `${text.substring(0, limit)}...`
  }
  return text
}


export async function getPhoneBookContacts() {
  const { data } = await Contacts.getContactsAsync({
    fields: [Contacts.Fields.Emails, Contacts.Fields.PhoneNumbers, Contacts.Fields.FirstName, Contacts.Fields.LastName, Contacts.Fields.Image],
  })

  const newContacts = data.map(contact => {
    return {
      id: contact.id,
      firsName: contact.firstName,
      lastName: contact.lastName,
      name: contact.name,
      phone: contact.phoneNumbers?.length > 0 ? contact.phoneNumbers[0]?.digits : null,
      email: contact.emails?.length > 0 ? contact.emails[0]?.email : null,
      image: contact.imageAvailable ? contact.image.uri : null,
      mobile: contact.phoneNumbers?.length > 0 ? getFormattedNumber(contact.phoneNumbers[0]) : null,
      source: "phone"
    }
  })

  return newContacts
}


export async function canRecieveNotifications() {
  if (Platform.OS === "web") {
    return;
  }
  if (Platform.OS !== "ios") {
    return true;
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    return existingStatus == "granted"
  } else {
    return false
  }
}


export async function registerForPushNotificationsAsync(force = false) {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      if (force) {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      } else {
        return null;
      }
    }
    if (finalStatus !== 'granted') {
      console.error('Failed to get push token for push notification!');
      return;
    }
    // Learn more about projectId:
    // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
    token = (await Notifications.getExpoPushTokenAsync({ projectId: "762b63a1-09f0-41d3-b1fa-eb6cf14d9478" })).data;
    return token
  } else {
    alert('Must use physical device for Push Notifications');
    return null
  }
}



export function getErrorString(error, defaultStr = null, statusCode = null) {
  console.log("error manager looking at")
  console.log(error)

  if (statusCode === 401) {
    return "An account is needed to perform that action"
  }
  if (error === null || error === undefined) {
    console.log("Error is null or undefined. Returning")
    if (defaultStr) {
      return defaultStr;
    }
    return "Sorry, something went wrong. We're looking into it. Try again later."
  }

  if (error.detail !== undefined) {
    console.log("Error has a detail!")
    return error.detail
  } else if (Array.isArray(error)) {
    return error[0]
  } else {
    try {
      console.log("Error has no detail")
      const fieldName = Object.keys(error)[0]
      const errorMessage = error[fieldName][0]
      return `${fieldName}: ${errorMessage}`
    }
    catch (e) {
      console.log("Everything failed. idk.")
      return defaultStr ?? "Sorry, something went wrong. We're looking into it. Try again later."
    }
  }
}

export function getErrorHandlePage(error, errorStr) {
  console.log("Handling error for ", error)
  if (error.statusCode === 401) {
    return { page: "Signup", param: {} }
  } else if (errorStr.includes("Invalid access.")) {
    var page = "Plans"
    let param: any = {}

    if (errorStr.includes("Feature")) {
      let split = errorStr.split(":")
      if (split.length > 1) {
        let sku = split[1].trim()
        param.feature = sku
      }
    }

    return { page: page, param: param }
  }
  return null
}


export function getSentryReleaseString() {
  var string = `reminderbase@${Constants.expoConfig.runtimeVersion}+1`
  return string;
}

export function handleRequestError(resp, pageProps = null, showAlert = false, defaultErrorStr = null) {
  const errorStr = getErrorString(resp.error, defaultErrorStr, resp.statusCode)
  const pageData = getErrorHandlePage(resp, errorStr)
  console.log("pageData from error", pageData)

  if (pageData && pageProps) {
    pageProps.navigation.push(pageData.page, pageData.param)
  }
  if (showAlert && !pageData) {
    Alert.alert("Error", errorStr)
  }
}

export function injectJavascript(src, async) {
  if (Platform.OS === "web") {
    injectJavascriptIntoWeb(null, src, async)
  }
}

export async function injectJavascriptIntoWeb(scriptText, src = null, async = false, textType = null, defer = null) {
  if (Platform.OS !== "web") {
    return
  }
  const script = document.createElement("script")
  if (textType) {
    script.setAttribute('type', 'text/javascript');
  }
  if (async) {
    script.setAttribute('async', '')
  }
  if (src) {
    script.setAttribute('src', src)
  }
  if (defer) {
    script.setAttribute('defer', '')
  }

  if (scriptText) {
    script.text = scriptText
  }

  const headList = document.getElementsByTagName("head");
  if (headList.length > 0) {
    const head = headList[0]
    head.appendChild(script)
  }
}

export async function captureSentryException(e) {
  if (Platform.OS == "web") {
    Sentry.Browser.captureException(e);
  } else {
    Sentry.Native.captureException(e)
  }
}


export async function waitFor(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function getContactName(contact, allowOtherLabels = false, localContactsMap = null) {
  if (contact?.name) {
    return contact.name
  }

  if (localContactsMap?.mobile && contact?.mobile) {
    const localContact = localContactsMap?.mobile[contact.mobile]
    if (localContact) {
      return localContact.name
    }
  }

  if (localContactsMap?.email && contact?.email) {
    const localContact = localContactsMap?.email[contact.email]
    if (localContact) {
      return localContact.name
    }
  }

  console.log("===ALLOW OTHER LABELS", allowOtherLabels)
  console.log("===CONTACT", contact)
  if (allowOtherLabels) {
    if (contact?.mobile) {
      return contact.mobile
    }
    if (contact?.email) {
      return contact.email
    }
  }
  return "Anonymous"
}

const camelToFlat = (camel) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")

  let flat = ""
  camelCase.forEach(word => {
    flat = flat + word + " "
  })
  flat = flat.substring(0, 1).toUpperCase() + flat.substring(1, flat.length - 1)
  return flat
}

export const snakeCaseToFlat = (snake) => {
  const snakeCase = snake.split("_")

  let flat = ""
  snakeCase.forEach(word => {
    flat = flat + word + " "
  })
  flat = flat.substring(0, 1).toUpperCase() + flat.substring(1, flat.length - 1)
  return flat
}

export const stripAndSplit = (str, split_item) => {
  return str.replace(/ /g, '').split(split_item)
}


export function showAlert(alertString) {
  if (Platform.OS === "web") {
    window.alert(alertString);
  } else {
    Alert.alert(alertString);
  }
}



export function buildURLQuery(obj) {
  if (obj) {
    return Object.entries(obj)
      .filter(pair => pair[1] !== undefined && pair[1] !== null)
      .map(pair => pair.map(encodeURIComponent).join('='))
      .join('&');
  } else {
    return ""
  }
}

export function searchLocalContacts(searchText: string, contactsBank: [any], limit: number = 3) {
  if (!contactsBank) return [];

  const filteredContacts = Object.values(contactsBank).filter(contact => 
    contact.name?.toLowerCase().includes(searchText.toLowerCase()) ||
    contact.mobile?.includes(searchText) ||
    contact.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  return filteredContacts.slice(0, limit).map(contact => ({
    type: "contact",
    name: contact.name || "Unknown",
    id: contact.id,
    contact: {
      name: contact.name,
      mobile: contact.mobile,
      email: contact.email,
      image: contact.image,
    },
    source: "local"
  }));
}
