import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Heading, Text, View, Button, Stack, Link, Center } from 'native-base'
import { LoadingModal } from "../components/LoadingModal";
import { Image } from 'react-native'
import MobileVerificationVC from "../controllers/VerifyPhoneVC";
import { injectJavascriptIntoWeb } from "../utils";
import { Platform } from "react-native";


export default function ListRegisterScreen(props) {

    const slug = props.route.params?.slug
    const userIdSlug = props.route.params?.user_id

    const listApi = new GenericViewSetAPI("contact_list")
    const userApi = new GenericViewSetAPI("user")

    const [listId, setListId] = useState(null)
    const [userId, setUserId] = useState(null)
    const [listData, setListData] = useState(undefined)
    const [finished, setFinished] = useState(false)
    const [listUser, setListUser] = useState(undefined)

    const getPublicListData = async(listId) => {
        const listResp = await listApi.nonStandard("GET", `${listId}/public`)
        console.log(listResp)
        if (listResp && !listResp.error) {
            setListData(listResp)
        } else { 
            setListData(null)
        }
    }

    const getPublicListUser = async(userId) => {
        const userResp = await userApi.nonStandard("GET", `${userId}/public`)
        console.log(userResp)
        if (userResp && !userResp.error) {
            setListUser(userResp)
        } else { 
            setListUser(null)
        }
    }

    useEffect(() => {
        console.log(slug)
        console.log(userIdSlug)
        console.log(props.route.params)
        let slugItem = slug ?? userIdSlug
        const parts = slugItem.split("-")
        const parsedListId = parts[parts.length - 1]
        if (slug) { 
            setListId(parsedListId)
        } else { 
            setUserId(parsedListId)
        }
        console.log(parsedListId)
        if (!userIdSlug) { 
            getPublicListData(parsedListId)
        } else { 
            getPublicListUser(parsedListId)
        }
        if (Platform.OS == "web") {
            injectJavascriptIntoWeb(null, "https://www.google.com/recaptcha/api.js", true, null, true)
        }
    }, [])


    const getListName = () => {
        if (listData) { 
            return listData.name
        } else if (listUser) { 
            let name = listUser.brand_name ?? listUser.first_name 
            return name + "'s List"
        }
    }

    const getListUser = () => {
        if (listData) { 
            return listData.user
        } else if (listUser) { 
            return listUser
        }
    }

    const hasListUserOrData = listUser || listData
    

    return <Layout minH="900">
        {listData === undefined && listUser == undefined &&  <LoadingModal visible={true}/>}
        {(listData === null || listUser === null) && <Text>Sorry, we couldn't load this list</Text>}
        {hasListUserOrData && <View w="100%" mt={40}>
                {!finished && <View w="100%">
                        <Image source={require("../assets/icon.png")} style={{width: 100, height: 100, borderRadius: 50, alignSelf: "center"}} />
                        <Stack mt={4} mb={4}>
                            <Heading  w="100%" textAlign={"center"}>Join {getListName()}</Heading>
                            <Text fontSize="md" textAlign={"center"}>by {getListUser().brand_name ?? getListUser().first_name + " " +  getListUser().last_name}</Text>
                        </Stack>
                        <Text color="coolGray.700" fontSize="sm" mt={2} textAlign={"center"}>By entering your phone number below, you are agreeing to recieve reminders and other communications from {getListUser().brand_name ?? getListUser().first_name + " " +  getListUser().last_name}. Please note that text message rates may apply and you can opt out at anytime from these messages by texting 'X'</Text>
                        <Text color="coolGray.700" fontSize="sm" mt={2} textAlign={"center"} bold>We'll never send you unwanted messages.</Text>

                        <MobileVerificationVC showName listId={listId} contactUserId={userId} onFinish={() => setFinished(true)} getTitleRender={() => <View mt={4}></View>} />

                        <Text mt={5} color="coolGray.700" fontSize="xs" textAlign={"center"}>By entering your number above, you are agreeing to our Messaging Terms of Service and Privacy Policy.</Text>
                        <Center mt={2}>
                            <Button size="sm" variant="subtle" onPress={() => window.location.href = "https://www.reminderbase.co"}>Powered by reminderbase.co</Button>
                        </Center>
                    </View>
                }

                {finished && <View w="100%">
                    <Heading  w="100%" textAlign={"center"}>You've successfully joined {getListName()}</Heading>
                    <Text mt={2} fontSize="md" textAlign={"center"}>Thanks for signing up! You've been added to this list. Reminderbase is the best way to schedule reminders for the things that matter.</Text>
                    <View alignItems={"center"}>
                        <Button maxW="500" size="lg" mt={4} onPress={() => window.location.href = "https://www.reminderbase.co"}>Create your own reminder list</Button>
                    </View>
                </View>}
            </View>}
    </Layout>
}