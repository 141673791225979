import { Button, Heading, Input } from "native-base";
import Layout from "../components/Layout";
import { useState } from "react";
import PhoneInput from "react-native-phone-number-input";


export default function VerifyPhoneScreen(props) { 

    const [phone, setPhone] = useState("")
    const [code, setCode] = useState("")
    const [stage, setStage] = useState("phone")

    const handleContinue = () => {
        if (stage == "phone") {
            // SEND CODE TO PHONE
            setStage("code")
        } else { 
            // VERIFY CODE
            props.navigation.push("OnboardUser", {name: "Victor Anyirah"})
        }
    }

    return <Layout>
        <Heading bold={false} mt={4} mb={2}>{stage == "phone"? "Enter your phone number" : "Enter the code you were texted"}</Heading>
        {/* <Input variant="underlined" size="lg" placeholder="Enter phone number..." value={phone} onChangeText={(txt) => setPhone(txt)} /> */}
        {stage == "phone" && <PhoneInput 
                ref={null}
                defaultValue={phone}
                defaultCode="US"
                layout="first"
                containerStyle={{width: "100%"}}
                value={phone}
                onChangeFormattedText={(text) => {
                    setPhone(text);
                }}
                withDarkTheme
                withShadow
                textInputProps={{returnKeyType: "done"}}
        />}
        {stage == "code" && <Input variant="underlined" size="lg" placeholder="Enter code..." keyboardType="numeric" value={code} onChangeText={(txt) => setCode(txt)} />}
        <Button mt={4} onPress={() => handleContinue()}>Continue</Button>
    </Layout>
}