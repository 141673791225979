/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Platform, Pressable } from 'react-native';
import LinkingConfiguration from './LinkingConfig';
import useColorScheme from '../hooks/useColorScheme';
import useViewType from '../hooks/useViewType';
import { View, Text, HStack, Icon } from 'native-base';
import WebHeader from '../components/WebHeaderRight';
import RemindersScreen from '../screens/RemindersScreen';
import ContactsScreen from '../screens/ContactsScreen';
import ChatsScreen from '../screens/ChatsScreen';
import CalendarScreen from '../screens/CalendarScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ReminderDraftScreen from '../screens/ReminderDraftScreen';
import ReminderSettingScreen from '../screens/ReminderSettingScreen';
import ContactListDetailScreen from '../screens/ContactsDraftScreen';
import ContactDetailScreen from '../screens/ContactDetailScreen';
import ChatScreen from '../screens/ChatScreen';
import ListSelectScreen from '../screens/ListSelectScreen';
import RecipientScreen from '../screens/RecipientScreen';
import MyContactsScreen from '../screens/MyContactsScreen';
import SignupScreen from '../screens/SignupScreen';
import VerifyPhoneScreen from '../screens/VerifyPhoneScreen';
import OnboardProfileScreen from '../screens/OnboardProfileScreen';
import ReminderSendingScreen from '../screens/ReminderSendingScreen';
import PlanScreen from '../screens/PlansScreen';
import ListRegisterScreen from '../screens/ListRegisterScreen';
import CustomPhoneScreen from '../screens/CustomPhoneScreen';
import RespondScreen from '../screens/RespondScreen';
import Loading from '../screens/Loading';
import PublicContactAdminScreen from '../screens/PublicContactAdminScreen';
import { UpdateRequiredScreen } from '../screens/UpdateRequired';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const NullComponent = () => null;

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<any>();

function RootNavigator() {
  const webHeader = () => Platform.OS == "web" ? {
    header: (props) => <WebHeader props={props}></WebHeader>
  } : null

  const nonDownloadHeader = () => Platform.OS == "web" ? {
    header: (props) => <WebHeader props={{...props, hideDownload: true}}></WebHeader>
  } : null
  return (
    <Stack.Navigator screenOptions={{headerBackTitleVisible: false, animation: Platform.OS === "android" ? "none" : "default"}} >
      {/* <Stack.Screen name="Loading" component={LoadingScreen} options={nonDownloadHeader} /> */}
      <Stack.Screen name="Loading" component={Loading} options={{headerShown: false}} />
      <Stack.Screen name="Signup" component={SignupScreen} />

      {Platform.OS == "web" && <>
         <Stack.Screen name="Root" component={RemindersScreen} options={webHeader} />
         <Stack.Screen name="Contacts" component={ContactsScreen} options={webHeader} />
          <Stack.Screen name="Chats" component={ChatsScreen} options={webHeader} />
          <Stack.Screen name="Calendar" component={CalendarScreen} options={webHeader} />
          <Stack.Screen name="Setting" component={SettingsScreen} options={webHeader} />
      </>}

      {Platform.OS !== "web" && <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />}
      <Stack.Screen name="Draft" component={ReminderDraftScreen} />
      <Stack.Screen name="ListRegister" component={ListRegisterScreen} options={webHeader} />
      <Stack.Screen name="UserListRegister" component={ListRegisterScreen} options={webHeader} />
      <Stack.Screen name="ReminderSetting" component={ReminderSettingScreen} options={{title: "Setting"}} />
      <Stack.Screen name="ContactsDraft" component={ContactListDetailScreen} />
      <Stack.Screen name="PublicContactAdmin" component={PublicContactAdminScreen} />
      <Stack.Screen name="UpdateRequired" component={UpdateRequiredScreen} />
      <Stack.Screen name="ContactDetail" component={ContactDetailScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} />
      <Stack.Screen name="MyContacts" component={MyContactsScreen} options={{title: "My Contacts"}} />
      <Stack.Screen name="Recipient" component={RecipientScreen} options={{title: "Select Recipients"}} />
      <Stack.Screen name="VerifyPhone" component={VerifyPhoneScreen} options={{title: "Verify"}} />
      <Stack.Screen name="OnboardUser" component={OnboardProfileScreen} options={{title: "Onboard"}} />
      <Stack.Screen name="CustomPhone" component={CustomPhoneScreen} options={{title: "Custom phone"}} />
      <Stack.Screen name="Plans" component={PlanScreen} options={{headerShown: false}} />
      <Stack.Screen name="Respond" component={RespondScreen} options={webHeader} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="SelectList" component={ListSelectScreen} />
        <Stack.Screen name="ReminderSending" component={ReminderSendingScreen} options={{title: "Send messages"}} />
      </Stack.Group>
    </Stack.Navigator>
    
  );
}


/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<any>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Reminders"
      screenOptions={{
        tabBarActiveTintColor: "blue",
      }}>
      <BottomTab.Screen
        name="Reminders"
        component={RemindersScreen}
        options={({ navigation }) => ({
          title: 'Home',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="home" color={color} />
        })}
      />

      <BottomTab.Screen
        name="Contacts"
        component={ContactsScreen}
        options={{
          title: 'Contacts',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="users" size={24} color={color} />,
        }}
      />

      <BottomTab.Screen
        name="Chats"
        component={ChatsScreen}
        options={{
          title: 'Chats',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="comments" color={color} />,
        }}

      />

      <BottomTab.Screen
        name="Calendar"
        component={CalendarScreen}
        options={{
          title: 'Appointments',
          // tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="inbox" color={color} />
          tabBarIcon: ({color}) => <FontAwesome name="calendar" color={color} size={28}/>
        }}
      />


      <BottomTab.Screen
        name="Setting"
        component={SettingsScreen}
        options={{
          title: 'Setting',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="gear" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function FontAwesomeTabBarIcon(props: {
  size?: number;
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={props.size ?? 30} style={{ marginBottom: -3 }} {...props} />;
}
