import { createStore } from 'redux'

const exampleInitialState = {
  user: null,
  guest: null,
  token: null,
  config: null, 
  targetScreen: null, 
  theme: null,
  contactsBank: null,
  userAccessJson: null,
  localContactsMap: null, 
  onboarding: false
}

export const actionTypes = {
    SET_USER: 'SET_USER', 
    SET_TOKEN: 'SET_TOKEN', 
    SET_TARGET_SCREEN: 'SET_TARGET_SCREEN',
    SET_CONFIG: 'SET_CONFIG',
    SET_THEME: 'SET_THEME', 
    SET_CONTACTS_BANK: 'SET_CONTACTS_BANK',
    SET_USER_ACCESS_JSON: "SET_USER_ACCESS_JSON",
    SET_OFFERING: "SET_OFFERING", 
    SET_LOCAL_CONTACTS_MAP: "SET_LOCAL_CONTACTS_MAP",
    SET_ONBOARDING: "SET_ONBOARDING"
}

// REDUCERS
export const reducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return Object.assign({}, state, {
        user: action.user,
      })
    case actionTypes.SET_TOKEN:
      return Object.assign({}, state, { 
        token: action.token
      })
    case actionTypes.SET_TARGET_SCREEN:
      return Object.assign({}, state, { 
        screen: action.screen
      })
    case actionTypes.SET_CONFIG:
      return Object.assign({}, state, {
        config: action.config
      })
    case actionTypes.SET_THEME:
        return Object.assign({}, state, {
            theme: action.theme
        })
    case actionTypes.SET_CONTACTS_BANK:
        return Object.assign({}, state, {
            contactsBank: action.contactsBank
        })
    case actionTypes.SET_USER_ACCESS_JSON:
        return Object.assign({}, state, {
            userAccessJson: action.userAccessJson
        })
    case actionTypes.SET_LOCAL_CONTACTS_MAP:
        return Object.assign({}, state, {
            localContactsMap: action.localContactsMap
        })
    case actionTypes.SET_ONBOARDING:
        return Object.assign({}, state, {
            onboarding: action.onboarding
        })
    default:
      return state
  }
}

export function setUser(user) { 
  if (user) { 
    user.username = user.username
  }
  return  {type: actionTypes.SET_USER, user: user }
}

export function setLocalContactsMap(localContactsMap) {
  return { type: actionTypes.SET_LOCAL_CONTACTS_MAP, localContactsMap: localContactsMap }
}

export function setToken(token) { 
  return {type: actionTypes.SET_TOKEN, token: token}
}

export function updateTheme(theme) { 
  return {type: actionTypes.SET_THEME, theme: theme }
}

export function setConfig(config) { 
  return { type: actionTypes.SET_CONFIG, config: config}
}

export function setTargetScreen(screen) { 
    return { type: actionTypes.SET_TARGET_SCREEN, screen: screen }
}

export function clearTargetScreen() {
    return { type: actionTypes.SET_TARGET_SCREEN, screen: null }
}

export function setContactsBank(contactsBank) {
  return { type: actionTypes.SET_CONTACTS_BANK, contactsBank: contactsBank }
}

export function setUserAccessJson(userAccessJson) {
  return { type: actionTypes.SET_USER_ACCESS_JSON, userAccessJson: userAccessJson }
}

export function setOnboarding(onboarding) {
  return { type: actionTypes.SET_ONBOARDING, onboarding: onboarding }
}

export function initializeStore (initialState = exampleInitialState) {
  return createStore(
    reducer,
    initialState
  )
}