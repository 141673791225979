import { Heading, View, Text, HStack, Button } from "native-base";


export default function EmptyFeatureView(props) { 


    return <View mt={4}>
    <Heading size="md" mb={1}>{props.heading}</Heading>
    <Text color={"gray.600"} mb={2}>{props.label}</Text>
    {!props.hideButton && <HStack>
        <Button onPress={props.onPress}>{props.buttonLabel}</Button>
    </HStack>}
    </View>

}