import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Heading, View, Text, Button, Pressable, Spinner, Skeleton, VStack, Badge, HStack, FormControl, Select, Stack, Switch, Box } from "native-base";
import Purchases, { PURCHASES_ERROR_CODE } from 'react-native-purchases'
import { Platform, Image, Alert, Linking } from "react-native";
import ConfirmModal from "../components/ConfirmModal";
import { LoadingModal } from "../components/LoadingModal";
import { SafeAreaView } from "react-native-safe-area-context";
import Analytics from "../utils/AnalyticsManager";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserAccessJson } from "../store";
import { stripAndSplit } from "../utils";
import SimpleModal from "../components/SimpleModal";
import PlanCard from "../components/PlanCard";


export default function PlanScreen(props) {
    const specificSku = props.route.params?.sku ?? props.route.params?.feature ?? null
    const userApi = new GenericViewSetAPI("user")
    const planApi = new GenericViewSetAPI("plan")
    const config = useSelector((state: any) => state.config)


    const dispatch = useDispatch()
    const useReminderbaseV2 = false
    const [packages, setPackages] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [loading, setLoading] = useState(false)
    const [planPackageMap, setPlanPackageMap] = useState({})
    const [currentPlanIdentifiers, setCurrentPlanIdentifiers] = useState(null)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [cancelReason, setCancelReason] = useState(null)
    const [showRestorePurchases, setShowRestorePurchases] = useState(false)
    const [showSwitch, setShowSwitch] = useState(true)
    const [relationshipMap, setRelationshipMap] = useState({})

    const testPlans = [
        {
            id: "starter",
            title: "Starter",
            price: 0,
            description: "Free",
            num_months: "1"
        }, 
        {
            id: "pro",
            title: "Pro",
            price: 4.99,
            description: "Monthly",
            num_months: "1"
        },
        {
            id: "pro",
            title: "Pro",
            price: 49.99,
            description: "Yearly",
            num_months: "12"
        }
    ]

    const getActiveSubs = async() => {
        try {
            const customerInfo = await Purchases.getCustomerInfo();
            console.log("active subscriptions==============")
            console.log(customerInfo.activeSubscriptions)
            if (customerInfo.activeSubscriptions.length == 0) {
                return null
            }
            return customerInfo.activeSubscriptions
            // access latest customerInfo
          } catch (e) {
           // Error fetching customer info
           return false
          }
    }

    const getPurchases = async () => {
        try {
          const offeringsRequest = Purchases.getOfferings();
          const plansRequest = planApi.query({})
          const [planResp, offerings] = await Promise.all([plansRequest, offeringsRequest])
          const activeSubs = await getActiveSubs()
          if (activeSubs) { 
            // console.error("Setting active subs!", activeSubs)
            setCurrentPlanIdentifiers(activeSubs)
          }
          console.log("offering are ", offerings)
          console.log("planResp", planResp)
          console.log("config", config)
          if (!planResp && planResp.error) {
                Alert.alert("Sorry", "We couldn't get the plans for Reminderbase. Please try again later")
                props.navigation.goBack() 
          }

          if (offerings.current !== null) {  
            let currentOffering = offerings.current
            // let currentOffering = offerings.current
            if (activeSubs || (currentOffering.availablePackages.length == 0 || currentOffering.availablePackages.length == 1)) { 
                // We need to have a seperate key for upgrade offering since offerings.current shows the user's current offering if they are
                // already paying.
                console.log("User is already subscribed. Checking for default upgrade offering")
                currentOffering = offerings.all[config.default_upgrade_offering]
                // setCurrentPlan(offerings.current.availablePackages[0].identifier)
            }

            console.log("current offering", currentOffering)
            const availablePackages = currentOffering.availablePackages
            for (var pkg of availablePackages) {
                console.log("Looking for plan for package", pkg.offeringIdentifier)
                const plan = planResp.results.find((p) => {
                    if (p.product_ids) { 
                        let product_ids = stripAndSplit(p.product_ids, ",")
                        console.log(`looking for ${pkg.product.identifier}`)
                        console.log(product_ids)
                        return product_ids.includes(pkg.product.identifier)
                    }
                    return false
                })
                    
                if (plan) {
                    console.log("Found plan for package..setting")
                    planPackageMap[pkg.identifier] = plan
                    let entitlement_id = plan.revenue_cat_id
                    if (relationshipMap[entitlement_id]) { 
                        const existing = relationshipMap[entitlement_id]
                        if (existing.identifier != pkg.product.identifier) {
                            relationshipMap[entitlement_id].push(pkg)
                        }
                    } else { 
                        relationshipMap[entitlement_id] = [pkg]
                    }
                    console.log("relationship map is ", relationshipMap)
                    setRelationshipMap({...relationshipMap})
                    // if (activeSubs.includes(pkg.identifier)) {
                }
            }
            console.log("available packages", availablePackages)
            setPackages([...availablePackages])
            console.log("relationshipMap,", relationshipMap)
          }
        } catch (e) {
          console.error("Error getting offerings", e);
        }
    }

    useEffect(() => {
        getPurchases()
        Analytics.logEvent("view_page", {page: "PlansScreen", source: props.route.params?.source ?? "normal"})
        // const fakeSkus = {skus: ["upload_reminder_image_2"]}
        // dispatch(setUserAccessJson(fakeSkus))
    }, [])


    const getSubtext = () => {
        if (specificSku) { 
            return `Upgrade your plan to access ${specificSku}. Cancel anytime.`
        } else { 
            return "Select a plan that's right for you. Cancel anytime"
        }
    }


    const purchasePlan = async () => {
        setLoading(true) 
        try { 
            const selected = await Purchases.purchasePackage(selectedPlan)
            const [updatedUser, featureAccessJsonResp] = await Promise.all([userApi.nonStandard("GET", "me"),  userApi.nonStandard("GET", "feature_access")])
            Alert.alert("Success", "Your plan has been updated! Restart the app if your new plan/features are not showing up.")
            if (featureAccessJsonResp && !featureAccessJsonResp.error) {
                dispatch(setUserAccessJson(featureAccessJsonResp))
                Analytics.logEvent("purchase_success")
            } else { 
                Alert.alert("Sorry", "Your purchase was successful but we couldn't refresh your account. Try killing and reopening the app.")
                Analytics.logEvent("purchase_feature_access_error", {error: featureAccessJsonResp})
            }
            if (updatedUser && !updatedUser.error) {
                dispatch(setUser(updatedUser))
                props.navigation.goBack()
            } else { 
                Alert.alert("Sorry", "Your purchase was successful but we couldn't refresh your account. Try killing and reopening the app.")
                Analytics.logEvent("purchase_user_refresh_error", {error: updatedUser})
            }
        } catch (e) { 
            console.log(e)
            if (e.userCancelled) {
                Analytics.logEvent("purchase_cancelled")
            } else {
                const code = e.code
                if (code === PURCHASES_ERROR_CODE.RECEIPT_ALREADY_IN_USE_ERROR) { 
                    setShowRestorePurchases(true)
                } else if (code === PURCHASES_ERROR_CODE.PRODUCT_ALREADY_PURCHASED_ERROR) { 
                    setShowRestorePurchases(true)
                } else if (code == PURCHASES_ERROR_CODE.OPERATION_ALREADY_IN_PROGRESS_ERROR) {
                    Alert.alert("Sorry", "We're currently processing your purchase. Please wait a few moments and try again.")
                    Analytics.logEvent("purchase_in_progress_error")
                } else { 
                    Alert.alert("Sorry", "We couldn't purchase your plan unfortunately. Please try again later or contact support@reminderbase.com if it persists.")
                    Analytics.logEvent("purchase_plan_error", {error: e})
                }
            }
        }

        setLoading(false)
    }

    const restorePurchase = async() => {
        try {
            const restore = await Purchases.restorePurchases();
            console.log("restore is ", restore)
            Alert.alert("Success", "Your purchase has been restored. Restart the app if your purchase is not showing up.")
            Analytics.logEvent("restore_purchase_success")
            const resyncedUser = await userApi.nonStandard("GET", "resync_plan")
            if (resyncedUser && !resyncedUser.error) {
                dispatch(setUser(resyncedUser))
            } 
          } catch (e) {
            console.log(e)
            Analytics.logEvent("restore_purchase_error", {error: e})
            if (!e.userCancelled) {
                Alert.alert("Sorry", "We couldn't restore your purchase. Please contact support@reminderbase.com for help if this persists")
            }
          }
          setShowRestorePurchases(false)       
    }

    const handleCancel = () => {
        Analytics.logEvent("cancel_plan_client", {reason: cancelReason})
        if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("You can cancel your plan", "Due to app policies, we can't cancel your plan for you. You'll need to do it from your app settings", 
            [{text: "Open setting", onPress: () => Linking.openURL('App-Prefs:APPLE_ACCOUNT&path=SUBSCRIPTIONS')}, 
            {text: "Cancel", onPress: () => setShowCancelModal(false)}
        ])
        }
    }

    return <Layout scrollView>
        <SafeAreaView>
            <Button variant="ghost" onPress={() => props.navigation.goBack()}>Go back</Button>
        </SafeAreaView>
        <View mb={20} justifyContent={"center"} w="100%">
        <View justifyContent={"center"} w="100%">
            <View mt={4} >
                <Image source={require("../assets/icon.png")} style={{width: 50, height: 50, borderRadius: 50, alignSelf: "center"}} />
                <Heading w="100%" mt={4} textAlign={"center"}>Reminderbase plans</Heading>
                <Text mt={2} mb={2} fontSize="md" textAlign={"center"}>{getSubtext()}</Text>

                {packages.length == 0 && <Spinner mt={4} />}
                {Object.keys(relationshipMap).map((item) =><PlanCard key={item} relationshipMap={relationshipMap} planPackageMap={planPackageMap} item={item} setSelectedPlan={(t) => setSelectedPlan(t)} />)}

                {/* {Object.keys(relationshipMap).map((item) =><PlanCard key={item} relationshipMap={relationshipMap} planPackageMap={planPackageMap} item={item} setSelectedPlan={() => setSelectedPlan(item)} />)} */}
            </View>
        </View>
    
        {currentPlanIdentifiers && <Button mt={4} w="100%" colorScheme={"danger"} variant={"subtle"} onPress={() => setShowCancelModal(true)}>Cancel plan</Button>}
        </View>
        <SimpleModal isOpen={showCancelModal} onClose={() => setShowCancelModal(false)} headerTitle="We're sorry to see you go." confirmLabel="Cancel plan"
        onConfirm={() => handleCancel()}>
            <Text>Please tell us why you're cancelling</Text>
            <FormControl>
                <FormControl.Label>Reason</FormControl.Label>
                <Select onValueChange={(val) => setCancelReason(val) }>
                    <Select.Item label="I no longer need the app" value="no_longer_needed" />
                    <Select.Item label="Messages not sending" value="messages_no_send" />
                    <Select.Item label="App issues" value="app_issues" />
                    <Select.Item label="Too expensive" value="too_expensive" />
                    <Select.Item label="Found a better alternative" value="found_something_better" />
                </Select>
            </FormControl>
            <Button isDisabled={!cancelReason} mt={4} onPress={() => handleCancel() }>Finish</Button>
        </SimpleModal>

        <SimpleModal isOpen={showRestorePurchases} onClose={() => setShowRestorePurchases(false)} headerTitle="Action needed">
            <Stack space={3}>
            <Heading size="md">Restore your purchase</Heading>
            <Text>We ran into an issue. Restore your purchase to resync any payments you've previous made.</Text>
            <HStack>
                <Button size="md" onPress={() => restorePurchase() }>Restore purchase</Button>
            </HStack>
            </Stack>
        </SimpleModal>

        {selectedPlan && <ConfirmModal isOpen onClose={() => setSelectedPlan(null)} title="Select plan" message={`Are you sure you'd like to select ${selectedPlan.product?.title} for your plan?`} confirmLabel="Update" onConfirm={purchasePlan}/>}
        {loading && <LoadingModal visible />}
    </Layout>
}