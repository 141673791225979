import { Button, Divider, FlatList, Input, Pressable } from "native-base"
import Layout from "../components/Layout"
import { useEffect, useState } from "react"


export default function ListSelectScreen(props){
    const onSelect = props.route.params?.onSelect 
    const renderItem = props.route.params?.renderItem
    const searchFilter = props.route.params?.searchFilter
    const allowSearch = props.route.params?.allowSearch ?? false
    const initialHydrateFetch = props.route.params?.initialHydrateFetch ?? null

    const [searchQry, setSearchQuery] = useState("")
    const [items, setItems] = useState(props.route.params?.items ?? [])
    const [loading, setLoading] = useState(false)

    const intialLoad = async() => {
        setLoading(true)
        if (initialHydrateFetch) {
            const hydratedItems = await initialHydrateFetch()
            if (hydratedItems) { 
                setItems(hydratedItems)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        props.navigation.setOptions({title: props.route.params?.title})
        props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={() => {
            props.navigation.goBack();
         }}>Done</Button>})

        //  intialLoad()
    }, [])


    useEffect(() => {
        if (searchFilter) {
            const newEntries = searchFilter(searchQry)
            setItems(newEntries)
        }
    }, [searchQry])


    const handleRender = (item) => {
        const data = item.item
        if (allowSearch && data == 0) { 
            return <Input w="100%" placeholder="Search" onChangeText={(e) => setSearchQuery(e) }/>
        } else if (data == 1) { 
            return <FlatList w="100%" data={items} renderItem={(item) => <Pressable onPress={() => {
                onSelect(item.item)
                props.navigation.goBack()
             }}>{renderItem(item.item)}</Pressable>}
            ItemSeparatorComponent={Divider}/>
        }

        return null
    }

    return <Layout>
        <FlatList w="100%" data={[0, 1]} renderItem={(item) => handleRender(item)}  ItemSeparatorComponent={Divider}/>
    </Layout>
}