import { FlatList, Heading, Input, View, Text, Divider, Pressable, Spinner } from "native-base";
import Layout from "../components/Layout";
import { useCallback, useEffect, useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import lodash from 'lodash'
import { Alert } from "react-native";
import ConfirmModal from "../components/ConfirmModal";
import { LoadingModal } from "../components/LoadingModal";

export default function CustomPhoneScreen(props) { 

    const useApi = new GenericViewSetAPI("user")
    const [areaCode, setAreaCode] = useState("")
    const [numbers, setNumbers] = useState(null);
    const [selectedNumber, setSelectedNumber] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)

    const debouncedFetch = lodash.debounce(fetchAreaCode, 500)
    
    useEffect(() => {
        debouncedFetch(areaCode)
    }, [areaCode])
    
    // const debouncedGetAreaCode = useCallback(debouncedFetch(areaCode), [areaCode])

    async function getPhoneNumber() { 
        setLoadingModal(true)
        const resp = await useApi.nonStandard("POST", "phone_number", {phone_number: selectedNumber}, null)
        if (resp && !resp.error) {
            Alert.alert("Success", "Your phone number has been updated")
            props.navigation.goBack()
        } else { 
            Alert.alert("Error", "There was an error updating your phone number")
        }
        setLoadingModal(false)
    }


    async function fetchAreaCode(newCode) { 
        setLoading(true)
        console.log("FETCHING AREA CODE", newCode)
        const resp = await useApi.nonStandard("GET", "phone_number", null, {area_code: newCode})
        console.log(resp)
        if (resp && !resp.error) { 
            setNumbers(resp)
        }
        setLoading(false)
    }


    return <Layout>
        <Heading mt={4} mb={2}>Area code</Heading>
        <Input size="lg" placeholder="Enter phone number area code..." value={areaCode} onChangeText={(text) => setAreaCode(text)} />
        
        {loadingModal && <LoadingModal visible/>}
        {loading && <Spinner  mt={4}/>}
        {!loading && <FlatList mt={4} keyboardShouldPersistTaps="always" w="100%" ItemSeparatorComponent={Divider} data={numbers} renderItem={(item: any) => <Pressable mt={2} mb={2} onPress={() => setSelectedNumber(item.item)}>
            <Text>{item.item}</Text>
        </Pressable>} />}

        <ConfirmModal isOpen={selectedNumber} onClose={() => setSelectedNumber(null)} title="Confirm phone number" message={`Are you sure you want to use ${selectedNumber}?`} onConfirm={() => getPhoneNumber()} />

    </Layout>
}