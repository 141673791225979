import { Button, Heading, Progress, Spinner, View , Text} from "native-base";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { Alert, Linking } from "react-native";
import * as SMS from 'expo-sms';
import Analytics from "../utils/AnalyticsManager";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import * as FileSystem from 'expo-file-system';

export default function ReminderSendingScreen(props) { 
    const image = props.route.params?.image

    const recipientApi = new GenericViewSetAPI("recipient")
    const contactApi = new GenericViewSetAPI("contact")
    const reminderApi = new GenericViewSetAPI("reminder")

    const [contactIndex, setContactIndex] = useState(0)
    const [reminder, setReminder] = useState(props.route.params?.reminder)
    const [reminderId, setReminderId] = useState(props.route.params?.reminderId ?? props.route.params?.reminder?.id)
    const [totalContactsCount, setTotalContactsCount] = useState(0)
    const [hasStartedSending, setHasStartedSending] = useState(false)
    const [contacts, setContacts] = useState(props.route.params?.contacts ?? [])
    const [currContactsResp, setCurrContactsResp] = useState(null)
    const [currContactListResp, setCurrContactListResp] = useState(null)
    const [finished, setFinished] = useState(false)
    const [isInitialLoading, setIsInitialLoading] = useState(true)


    const fetchData = async() => {
        if (!reminder) { 
            const reminderResp = await reminderApi.getById(reminderId)
            console.log("Reminder sending reminder resp", reminderResp)
            if (reminderResp && !reminderResp.error) {
                setReminder(reminderResp)
            }
        }
        const context = "mobile_personal_sending"
        console.log("reminder id", reminder)
        const allCount = await recipientApi.nonStandard("GET", "all_recipient_count", null, {reminder_id: reminderId, context: context})
        console.log("allCount", allCount)
        if (allCount && !allCount.error) {
            if (allCount.count > 500) { 
                Alert.alert("Over 500 recipients", "Consider sending with a Reminderbase number (no need to manually send each one) instead of your personal number to save time")
            }
            setTotalContactsCount(allCount.count)
        }
        
        var startContactsResp = null
        var totalContacts = []
        while (!startContactsResp || startContactsResp?.next ) {
            console.log(startContactsResp)
            const contactsResp = await recipientApi.query({reminder_id: reminderId, type: "contact", context: context})
            console.log('contacts Resp', contactsResp)
            if (contactsResp && !contactsResp.error) {
                setCurrContactsResp(contactsResp)
                startContactsResp = contactsResp
                const recipientContacts = contactsResp.results.map((r) => r.contact)
                console.log(recipientContacts)
                totalContacts = [...contacts, ...recipientContacts]
                setContacts(totalContacts)
            } else { 
                break;
            }
        }

        var contactListsStartResp = null
        while (!contactListsStartResp || contactListsStartResp?.next) {
            const contactListRecipientsResp = await recipientApi.query({reminder_id: reminderId, type: "contact_list", context: context})
            console.log("contact list resp", contactListRecipientsResp)
            if (contactListRecipientsResp && !contactListRecipientsResp.error) {
                for (var recipient of contactListRecipientsResp.results) {
                    const contactList = recipient.contact_list
                    const newContacts = await contactApi.query({contact_list: contactList.id})
                    console.log("contact list contacts resp", newContacts)
                    if (newContacts && !newContacts.error) {
                        totalContacts = [...totalContacts, ...newContacts.results]
                        setContacts(totalContacts)
                    }
                }
                setCurrContactListResp(contactListRecipientsResp)
                contactListsStartResp = contactListRecipientsResp
            } else { 
                break;
            }
        }

        console.log(totalContacts)
        setIsInitialLoading(false);
    }


    useEffect(() => {
        Analytics.logEvent("view_page", {page: "ReminderSendingScreen"})
        // Show done button 
        props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={() => {
            const propsQuery: any = {}
            if (props.route.params?.created) {
                propsQuery.createdReminderId = reminder.id
            }
            console.log("props query", propsQuery)
            props.navigation.navigate("Reminders", propsQuery)
        }}>Done</Button>})

        // Step 1 -- get full total count of all contacts for the remidner including contacts in contact list

        // Step 2 -- fetch all contacts. Keep fetching even after contactIndex is close to the end. 

        // Step 3 -- after running out of contacts, start fetching contacts from contact list. Keep increasing index until that is exhausted. Then you are done!
        console.log("FETCHING REMINDER SENDING SCREEN")
        fetchData()

    }, [])


    const testSms = async() => {
        const isAvailble = await SMS.isAvailableAsync();
        console.error(isAvailble)
        if (!isAvailble) { 
            Alert.alert("SMS is not available on this device.")
            return;
        }
        var attachments = null
        console.log("image", image)
        if (image) { 
            attachments = {
                uri: await FileSystem.getContentUriAsync(image.uri), 
                mimeType: 'image/png',
                filename: "attached_image.png", 
            }
            console.log(attachments)
        }

        for (var i = 0; i < totalContactsCount; i++) {
            console.log('i=', i)
            const contact = contacts[i]
            console.log(contacts)
            console.log(contacts.length)
            console.log("contact for i is ", contact)
            if (!contact || !contact.mobile) { 
                continue
            }
            var finalText = ""
            if (reminder?.title) { 
                finalText = reminder?.title + "\n\n"
            }
            if (reminder?.message) { 
                finalText += reminder?.message
            }

            if (contact.name) {
                const [first_name, last_name] = contact.name.split(" ")
                const replaceMents = {
                    "{first_name}": first_name ?? "", 
                    "{last_name}": last_name ?? ""
                }
                
                for (var key of Object.keys(replaceMents)) { 
                    finalText = finalText.replace(key, replaceMents[key])
                }
            }

            // Alert.alert("DONE")
            const { result } = await SMS.sendSMSAsync(
                contacts[i].mobile, finalText, {attachments: attachments}
              );
            if (result === 'sent') {
                // alert('Message sent!');
            } else {
                // alert('Message send failed.');
            }
            setContactIndex(i+1)
        }
        setFinished(true)
    }

    const getSpinnerValue = () => {
            if (finished) { 
                return 100
            }
            if (contactIndex == 0) { 
                return 0
            } 
            return contactIndex / contacts.length
    }


    return <Layout>
        <View w="100%" height="80%" justifyContent={"center"}>
            {isInitialLoading && <Spinner size="lg" />}
            {!isInitialLoading && <><Heading textAlign={"center"}>{hasStartedSending ? "Sending messages..." : "Ready to send messages"}</Heading>
                <Text mt={2} textAlign={"center"}>Sent {contactIndex} of {totalContactsCount} messages</Text>
                <View w="100%" mt={4}>
                    <Progress size="lg" value={getSpinnerValue()}/>
                </View>

                {!hasStartedSending && <Button  mt={10} size="lg" isDisabled={finished} onPress={() => testSms()}>{finished ? "Done sending" : "Send messages"}</Button>}
            </>}
        </View>
    </Layout>
}