import { useFocusEffect } from "@react-navigation/native";
import { Center, Heading, View, Text, Button, Container } from "native-base";
import React, { useEffect } from "react";
import { Linking, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import { setConfig } from "../store";
import { meetsMinimumAppVersion } from "../utils";
import Analytics from "../utils/AnalyticsManager";


export function UpdateRequiredScreen(props) {
    const userApi = new GenericViewSetAPI("user")


    const config = useSelector((state: any) => state.config)
    const dispatch = useDispatch();

    useEffect(() => {
        Analytics.logEvent("VIEW_UPDATE_REQUIRED");
    })

    const fetchAndCheckConfig = async() => {
        const userConfig = await userApi.nonStandard("GET", "config");
        console.log(userConfig)
        if (userConfig && !userConfig.error) { 
          dispatch(setConfig(userConfig));
        }
        if (meetsMinimumAppVersion(userConfig)) {
            Analytics.logEvent("refresh_minimum_version_met"); 
            props.navigation.replace("Login")
        } 
    }

    const handleStoreOpen = () => { 
        let url = null;
        if (Platform.OS == "ios") { 
            url = config.ios_url
        } else if (Platform.OS == "android") { 
            url = config.android_url
        }
        if (url) { 
            Linking.openURL(url);
        }
    }

    return <Layout>
        <Center flex={1} w="100%">
            <Heading pb="2">Update Required</Heading>
            <Text textAlign={"center"} pb="5">Your current Reminderbase version is no longer supported. Please update to the next available version</Text>
            <View w="100%">
                <Button onPress={handleStoreOpen} mb="2">Update</Button>
                <Button variant={"subtle"} colorScheme="coolGray" onPress={() => fetchAndCheckConfig()}>Refresh</Button>
            </View>
        </Center>
    </Layout>
}