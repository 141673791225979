

const paginatedResp = (data, error=null, page=1, next="random", prev=null, total=100) => {
   return {
    data: data, 
    page: page, 
    next: next,
    prev: prev,
    total: total, 
    error: error
   } 
}

const reminderResp: any = paginatedResp([
    {
        id: 1,
        title: "Test reminder",
        text: "This is a test reminder for {name}. We're having service at 10am on Sunday. Hope to see you there!",
        date: "2021-08-01T00:00:00.000Z",
        time: "2021-08-01T00:00:00.000Z",
        tz: "America/Los_Angeles",
        frequency: 'Weekly',
        contact_list_id: 2,
        contact_list: {
            name: "My contact list",
            id: 2,
            member_count: 10
        },
        to: "+12146769175, +12456769175",
        recipient_count: 3,
        limited_recipients: [
            {
                id: 1,
                name: "A",
                phone: "+12146769175",
                email: "swdawd@t.com"
            }, 
            {
                id: 1,
                name: "B",
                phone: "++12456769175",
                email: "swdawd@t.com"
            }
        ]
    },
    {
        id: 2,
        title: "Time to clean",
        text: "Hey it's time to clean. Get to it!!",
        date: "2021-08-01T00:00:00.000Z",
        time: "2021-08-01T00:00:00.000Z",
        tz: "America/Los_Angeles",
        frequency: 'One-off',
        to: "+12146769175, +12456769175",
        contact: {
            id: 1,
            name: "Test Contact 1",
            phone: "1234567890",
            email: "swdawd@t.com"
        }
    }
])

const pastRemindersResp = paginatedResp([
    {
        id: 1,
        title: "Past reminder",
        text: "This is a test reminder for {name}. We're having service at 10am on Sunday. Hope to see you there!",
        date: "2021-08-01T00:00:00.000Z",
        time: "2021-08-01T00:00:00.000Z",
        tz: "America/Los_Angeles",
        frequency: 'Weekly',
        contact_id: 1,
        contact_list: {
            name: "My contact list",
            id: 2,
            member_count: 10
        },
        to: "+12146769175, +12456769175",
        contact: {
            id: 1,
            name: "Test Contact 1",
            phone: "1234567890",
            email: "swdawd@t.com"
        }
    }
])

const contactListsResp: any = paginatedResp([
    {
        id: 1, 
        name: "My contact list",
        member_count: 10
    },
    {
        id: 2,
        name: "New contact list",
        member_count: 5
    }
])

const contactsResp: any = {
    contacts_count: 10, 
    new: 5, 
    unsubs: 2
}


const chatMessagesResp: any = paginatedResp([
    {
        id: 1, 
        // sender: "+12146769175",
        contact: {
            id: 1,
            name: "Test Contact 1",
            phone: "1234567890",
            email: "test@test.com"
        },
        text: "Hello -- when are you going to be open this weekend?",
        created_at: new Date().toUTCString(),
        is_user: false,
        chat_id: 1
    }, 
    {
        id: 2,
        text: "We're open from 9am to 5pm",
        is_user: true, 
        chat_id: 1,
        created_at: new Date().toUTCString()
    }
])

const recipientResp: any = paginatedResp(
    [
        {
            id: 1,
            name: "A",
            phone: "+12146769175",
            email: "swdawd@t.com"
        }, 
        {
            id: 1,
            name: "B",
            phone: "++12456769175",
            email: "swdawd@t.com"
        }
    ])

const contactListMembers: any = paginatedResp([
    {
        id: 1,
        name: "Victor Anyirah",
        phone: "+12146769175",
        email: "victoranyirah@gmail.com",
        status: "subcribed"
    },
    {
        id: 2,
        name: "James McGill", 
        phone: "+15234412323",
        status: "awaiting_confirmation"
    }
])


const templateResp: any = paginatedResp([
    {
        id: 1,
        title: "Test reminder",
        text: "This is a test reminder for {name}. We're having service at 10am on Sunday. Hope to see you there!",
        date: "2021-08-01T00:00:00.000Z",
        time: "2021-08-01T00:00:00.000Z",
        tz: "America/Los_Angeles",
        frequency: 'Weekly',
        is_template: true
    },
    {
        id: 2,
        title: "Time to clean",
        text: "Hey it's time to clean. Get to it!!",
        date: "2021-08-01T00:00:00.000Z",
        time: "2021-08-01T00:00:00.000Z",
        tz: "America/Los_Angeles",
        frequency: 'One-off',
        is_template: true
    }
])


const chatResp: any = paginatedResp([
    {
        number: "(555) 564-8583",
        latest_message: {
            text: "Hello world",
            created_at: new Date().toUTCString()
        }
    },
    {
        number: "+12146769175",
        latest_message: {
            text: "How are you??",
            created_at: new Date().toUTCString()
        }
    },
    {
        number: "(214) 676-9175",
        latest_message: {
            text: "How are you??",
            created_at: new Date().toUTCString()
        }
    }
])

const user = {
    id: 231,
    email: "victoranyirah@gmail.com",
    name: "Victor Anyirah",
    picture_url: null,
    phone: "+12146769175",
    plan: "Pro",
    sms_limit: 100,
    mms_limit: 20,
    sms_sender: "+186623244591",
    email_sender: "Reminders",
    brand: "Victor Anyirah"
}

const data = {
    USER: user,
    CONTACT_METRICS_RESP: contactsResp, 
    REMINDERS_RESP: reminderResp,
    CONTACTS_LIST_RESP: contactListsResp, 
    CHAT_RESP: chatResp,
    CONTACT_MEMBERS_RESP: contactListMembers,
    TEMPLATES_RESP: templateResp,
    PAST_REMINDERS_RESP: pastRemindersResp,
    CHAT_MESSAGES_RESP: chatMessagesResp
}

export default data;