import { FlatList, Heading, Spinner, View, Text, Divider, HStack, Pressable, Image } from "native-base";
import Layout from "../components/Layout";
import { useCallback, useEffect, useState } from "react";
import Placeholder from "../Constants/Placeholder";
import ProfilePicture from "../components/ProfilePicture";
import moment from "moment-timezone";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import EmptyFeatureView from "../components/EmptyFeatureView";
import { useFocusEffect } from "@react-navigation/native";
import Analytics from "../utils/AnalyticsManager";
import SearchBar from "../components/SearchBar";
import Data from "../Constants/Data";

export default function ChatsScreen(props) { 

    const chatApi = new GenericViewSetAPI("chat")

    const [chatsResp, setChatsResp] = useState(null);
    const [chats, setChats] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [page, setPage] = useState(1)

    useFocusEffect(useCallback(() => {
        Analytics.logEvent("view_page", {page: "ChatsScreen"})
        fetchChats()
    }, []))

    async function fetchChats(page=1, overwrite=true) { 
        let query: any = {page: page, page_size: Data.pageSize}
        if (searchQuery) { 
            query.search = searchQuery
        }
        const chatResp = await chatApi.query(query)
        console.error(chatResp)
        if (chatResp && !chatResp.error) { 
            setChatsResp(chatResp)
            if (overwrite) { 
                setChats(chatResp.results)
                setPage(1)
            } else { 
                setChats([...chats, ...chatResp.results])
            }
        } else { 
            setChats([])
        }
    }

    useEffect(() => {
        if (searchQuery === "") {
            fetchChats()
        }
    }, [searchQuery])

    async function handleLoadMore() { 
        console.log("CHATS SCREEN END REACHED", chatsResp)
        if (chatsResp.next) {
            const newPage = page + 1 
            await fetchChats(newPage, false)
            setPage(newPage)
        }
    }

    async function handleRefresh() { 
        setRefreshLoading(true)
        await fetchChats()
        setRefreshLoading(false)
    }

    function renderChat(chat) {
        if (chat == "search"){
            return <SearchBar placeholder="Search chats.." searchQuery={searchQuery} onChangeText={(text) => setSearchQuery(text)} onSearch={fetchChats} />

        }
        return <Pressable mt={3} mb={3} maxW={"100%"} onPress={() => props.navigation.push("Chat", {chatId: chat.id})}>
            <HStack maxW={"100%"}>
                <ProfilePicture user={{id: -1, first_name: chat.contact?.name ?? "Guest"}} />
                <View ml={3} w="100%">
                    <HStack maxW="90%" justifyContent="space-between">
                        <Heading size="sm" fontWeight={"semibold"}>{chat.contact?.name ?? chat.recipient_number}</Heading>
                    </HStack>
                    <Text mt={1}>{chat.latest_message?.text ?? "..."}</Text>
                    {chat.latest_message && <Text mt={1} fontSize="xs" color="gray.500">{moment(chat.latest_message?.created_at).format('lll')}</Text>}
                </View>
            </HStack>
            
        </Pressable>
    }
    
    return <Layout>
        {!chats && <Spinner mt={3}/>}
        {chats && chats.length == 0 && searchQuery == null && <EmptyFeatureView heading="No conversations" label="A conversation will be added here when someone responds to your reminder text" hideButton /> }
        {chats && <FlatList refreshing={refreshLoading} onRefresh={handleRefresh} ItemSeparatorComponent={Divider} showsVerticalScrollIndicator={false} minW="100%"
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.1}
     data={["search", ...chats]} renderItem={(item) => renderChat(item.item)} />}
    </Layout>
}