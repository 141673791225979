import Analytics from "../utils/AnalyticsManager"
import BaseApiConfigProvider from "./BaseApiConfigProvider"

const print = console.log

//DO NOT CHANGE THIS. ALL USERS WILL BE LOGGED OUT.
export default class BaseServer {

	private url: string
	private appVersion: string

	constructor(url, appVersion: string = null) {
		this.url = url
		this.appVersion = appVersion
	}

	protected async handleErrorV2(response: Response) {
		console.log(response)
		if (!response.ok) {
			try {
				const errorBody = await response.json()
				console.log('json error body', errorBody)
				Analytics.logEvent("SERVER_ERROR", { error: errorBody, statusCode: response.status })
				const failBody = { code: -1, error: errorBody, statusCode: response.status }
				return failBody
			} catch (e) {
				console.log("error exception", e)
				return { code: -1, error: e, statusCode: response.status }
			}
		}
		print("Successful response:")
		if (response.status === 204) { return {} }
		return await response.json();
	}

	protected async handleErrors(response) {
		console.log(response.status)
		if (!response.ok) {
			Analytics.logEvent("SERVER_ERROR", { error: response })
			try {
				const errorBody = await response.json()
				const failBody = { code: -1, error: errorBody, statusCode: response.status }
				console.log('json fail body', failBody)
				return failBody
			} catch (e) {
				return { code: -1, error: e, statusCode: response.status }
			}
		}
		print("Successful response:")
		
		if (response.status === 204) { return {} }
		return response.json();
	}

	protected async get(endpoint) {
		print(this.url + endpoint)
		var headers = {
			'Authorization': BaseApiConfigProvider.getTokenAuthStr(),
			'App-Version': this.appVersion,
		}
		try {
			const data = await fetch(this.url + endpoint, {
				mode: 'cors',
				headers: headers
			})
				.then(res => this.handleErrors(res))
				.catch((error) => { return error })

			return data
		} catch {
			return undefined
		}
	}

	protected async genericRequest(method, endpoint, body) {
		var resp = null
		try {
			resp = await fetch(this.url + endpoint, {
				method: method,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': BaseApiConfigProvider.getTokenAuthStr(), 
					'App-Version': this.appVersion,
				},
				body: body ? JSON.stringify(body) : null
			});
		} catch (e) {
			// AnalyticsManager.logEvent(events.SERVER_ERROR, { error: e })
			return { code: -1, error: "Unknown response error" }
		}

		if (resp) {
			if (resp.status === 204) {
				return {is_empty: true}
			}
			const jsonData = await this.handleErrorV2(resp);
			return jsonData;
		} else {
			return { code: -1, error: "Unknown response error" }
		}
	}

	protected async post(endpoint, body) {
		return await this.genericRequest("POST", endpoint, body);
	}

	protected async put(endpoint, body) {
		return await this.genericRequest("PUT", endpoint, body);
	}

	protected async patch(endpoint, body) {
		return await this.genericRequest("PATCH", endpoint, body);
	}


	protected async uploadMedia(endpoint, data, url=null) {
		const headers = url ? null : {
			'Authorization': BaseApiConfigProvider.getTokenAuthStr(),
			'App-Version': this.appVersion
		}
		var url = url ? url : this.url + endpoint
		return await fetch(url, {
			method: 'post',
			headers: headers,
			body: data
		}).then(res => { return this.handleErrors(res) })
			.catch((error) => { return { code: -1, error: error } })
	}

	protected async simplePost(url, body) { 
		return await fetch(url, {
			method: 'POST',
			body: body
		}).then((response) => { return this.handleErrors(response) })
		.catch((error) => { 
			console.log("ERROR", error)
			return { code: -1, error: error } })
	}

	protected async nonJsonPost(endpoint, body) {
		print(this.url)
		print(endpoint)
		return await fetch(this.url + endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': BaseApiConfigProvider.getTokenAuthStr(),
				'App-Version': this.appVersion
			},
			body: body
		})
			.then((response) => { return this.handleErrors(response) })
			.catch((error) => { return { code: -1, error: error } })
	}

	protected async delete(endpoint) {
		return await this.genericRequest("DELETE", endpoint, null);
	}


}
