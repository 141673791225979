import { Button, FormControl, Heading, Input, Select } from "native-base";
import Layout from "../components/Layout";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { showAlert } from "../utils";
import Analytics from "../utils/AnalyticsManager";
import { setUser } from "../store";

export default function OnboardProfileScreen(props) { 
    const userCredential = props.route.params?.userCredential
    const user = useSelector((state: any) => state.user)
    const displayName = props.route.params?.displayName ?? userCredential?.user?.displayName
    console.log(props.route.params?.displayName)
    const dispatch = useDispatch()

    const userApi = new GenericViewSetAPI("user")

    const [name, setName] = useState(user?.name ?? displayName ?? "" )
    const [brandName, setBrandName] = useState(user?.brand_name ?? "")
    const [selectedSource, setSelectedSource] = useState(null)
    const [sendLoading, setSendLoading] = useState(false)

    const handleContinue = async () => {
        setSendLoading(true)
        if (selectedSource) { 
            Analytics.logEvent("onboard_source", {source: selectedSource})
        }
        const updateBody: any = {}
        if (name != user.name) {
            updateBody.name = name
        }
        if (brandName != user.brand_name) {
            updateBody.brand_name = brandName
        }
        
        const userResp = await userApi.update(user.id, updateBody)
        if (userResp && !userResp.error) {
            dispatch(setUser(userResp))
            props.navigation.navigate("Root")
        } else { 
            showAlert("Sorry, we couldn't update your details. Please try again later.")
        }
        setSendLoading(false)
    }

    return <Layout>
        <Heading bold={false} mt={4} mb={2}>Your details</Heading>

        <FormControl mt={2}>
            <FormControl.Label>Your name</FormControl.Label>
            <Input variant="underlined" size="lg" placeholder="Enter name..." value={name} onChangeText={(txt) => setName(txt)} />
        </FormControl>

        <FormControl mt={2}>
            <FormControl.Label>Your brand name (optional)</FormControl.Label>
            <Input variant="underlined" size="lg" placeholder={name} value={brandName} onChangeText={(txt) => setBrandName(txt)} />
            <FormControl.HelperText>Defaults to your name when left empty. This is what subscribers will see when they get your texts or go to your subscribe link</FormControl.HelperText>
        </FormControl>

        <FormControl mt={4} mb={2}>
            <FormControl.Label>How did you find out about Reminderbase?</FormControl.Label>
            <Select onValueChange={(val) => setSelectedSource(val)}>
                <Select.Item label="Google" value="google" />
                <Select.Item label="Facebook" value="facebook" />
                <Select.Item label="Twitter" value="twitter" />
                <Select.Item label="Friend" value="friend" />
                <Select.Item label="Tiktok" value="tiktok" />
                <Select.Item label="Other" value="other" />
            </Select>
        </FormControl>

        

        <Button isLoading={sendLoading} mt={4} onPress={() => handleContinue()}>Continue</Button>
    </Layout>
}