import moment from "moment";
import { Heading, View, Text, HStack, Badge, Button, theme, Pressable } from "native-base";
import { Share } from "react-native";
import ParsedText from "react-native-parsed-text"
import { getContactList, getContacts, getToString } from "../utils";
import { useSelector } from "react-redux";


export default function ReminderListCell(props) { 

    const patternWords = ["first_name", "last_name"]

    const localContactsMap = useSelector((state: any) => state.localContactsMap)

    const reminder = props.reminder
 
    const getToNamesStr = (limit = 3) =>  {
        if (!reminder.to) { 
            return ""
        }
        let toList = reminder.to.split(",")
        let length = toList.length
        let prefix = toList.slice(0, limit).join(", ")
        if (length > limit) { 
            return `${prefix}+${length - limit} more`
        } else {
            return prefix
        }
    }


    const getScheduledDateStr = () => {
        if (reminder.scheduled_at) {
            return moment(reminder.scheduled_at).tz(reminder.timezone).format("llll") + " " + reminder.timezone
        }
        if (reminder.datepicker) { 
            return moment(reminder.datepicker).tz(reminder.timezone).format("lll") + " " + reminder.timezone
        } else { 
            return null;
        }
        return null
    }


    const getExpiresDateStr = () => {
        if (reminder.type === "One-off") {
            return null;
        }
        if (!reminder.expires_at) {
            return null;
        }

        if (reminder.expires_at) { 
            return moment(reminder.expires_at).tz(reminder.timezone).format('llll')
        }

        return null;
    }

    const getBadgeForReminder = () => { 
        var scheme = "light"
        if (reminder.frequency === "weekly") { 
            scheme = "green"
        } else if (reminder.frequency === "monthly") {
            scheme = "amber"
        } else if (reminder.frequency === "daily") { 
            scheme = "red"
        }
        return <Badge rounded={"full"} colorScheme={scheme}>{reminder.frequency}</Badge>
    }


    const isReoccuring = () => {
        if (reminder.frequency != "one-off") { 
            return true;
        } else { 
            return false;
        }
    }

    const isTemplate = reminder?.is_template;

    return <Pressable onPress={props.onCellPress} maxW="100%" p={3} mb={2} mt={2} borderColor={'gray.300'} borderWidth={1} rounded={"lg"}>
        <Heading size="md" mb={2} fontSize={"2xl"} fontWeight={"medium"} color={reminder.is_template ? "amber.600" : null}>{reminder.title}</Heading>
        <ParsedText numberOfLines={2} style={{marginBottom: 10}}  parse={[
            { pattern:/{first_name}|{last_name}|{name}/, style: { fontWeight: 'bold', color: theme.colors.blue[500] } } 
        ]}>
            {reminder.message}
        </ParsedText>
        { !isTemplate && <>
            <Text color="gray.600" fontSize={"sm"}>to: <Text fontWeight={"semibold"} color={"blue.500"}>{getToString(reminder?.contacts, reminder?.contact_list, reminder?.recipient_count, localContactsMap)}</Text></Text>
            <HStack mt={1}>
                {getScheduledDateStr() && <Text color="gray.600" fontSize="xs">Scheduled: {getScheduledDateStr()}</Text>}
            </HStack>

            {getExpiresDateStr() && isReoccuring() &&  <Text color="gray.600" fontSize="xs">Expires: {getExpiresDateStr()}</Text>}

            <HStack mt={2} justifyContent={"space-between"} alignItems={"center"}>
                <HStack>
                    {getBadgeForReminder()}
                    {reminder.under_review && <Badge colorScheme="info">Under review</Badge>}
                </HStack>
                <HStack>
                    <Button variant="ghost" onPress={props.onCellPress}>Manage</Button>
                    {/* {isReoccuring() && <Button variant="subtle" pt={0} pb={0} onPress={() => Share.share({url: getReminderShareLink(reminder)})}>Share susbcribe link</Button>} */}
                </HStack>

            </HStack>
        </>}

        {/* <HStack>
            {isReoccuring() && <Badge>{reminder.frequency}</Badge>}
            {isReoccuring() && <Button>Get subscribe link</Button>}
            <Button>Manage</Button>
        </HStack> */}
    </Pressable>
}