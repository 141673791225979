import { Actionsheet } from "native-base";

export default function ContactAddActionSheet(props) { 

    return <Actionsheet isOpen={props.isOpen} onClose={props.onClose}>
         <Actionsheet.Content>
                {!props.hideContactList && <Actionsheet.Item onPress={() => props.onSelect("contact_list")} >Select from contact lists</Actionsheet.Item>}
                <Actionsheet.Item onPress={() => props.onSelect("phone_contacts") }>Select from contacts</Actionsheet.Item>
                <Actionsheet.Item onPress={() => props.onSelect("manual_info")}>Enter manual information</Actionsheet.Item>
                {!props.hideImport && <Actionsheet.Item onPress={() => props.onSelect("import_csv")}>Import from CSV</Actionsheet.Item>}
        </Actionsheet.Content>
        </Actionsheet>
}