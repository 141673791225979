import { Button, Fab, FlatList, View, Text, HStack, Input, Icon } from "native-base";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import SimpleFab from "../components/SimpleFab";
import ContactAddActionSheet from "../components/ContactAddActionSheet";
import Placeholder from '../Constants/Placeholder'
import { Alert } from "react-native";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { getContactList, getContactName, getContacts } from "../utils";
import { Feather } from "@expo/vector-icons";
import { useSelector } from "react-redux";

export default function RecipientScreen(props) { 
    
    const reminder = props.route.params?.reminder ?? null
    const localContactsMap = useSelector((state: any) => state.localContactsMap)

    const recipientApi = new GenericViewSetAPI("recipient")
    const contactListApi = new GenericViewSetAPI("contact_list")

    const [contacts, setContacts] = useState(props.route.params?.contacts ?? []);
    const [contactLists, setContactLists] = useState(props.route.params?.contactLists ?? null)
    const [addContactModalOpen, setAddContactModalOpen] = useState(false)
    const [allContactLists, setAllContactLists] = useState([])
    const [searchQuery, setSearchQuery] = useState(null)
    
    useEffect(() => {
        // add done button to top right
        console.log(props.route.params)
        console.log(contactLists)
        console.error(contacts)
        props.navigation.setOptions({
            headerRight: () => <Button variant="ghost" onPress={() => props.navigation.goBack()}>Done</Button>
        })
        fetchAllContactLists()
        if (reminder?.id) { 
            fetchRecipients()
        }
    }, [])

    const fetchAllContactLists = async () => {
        const allContactListsResp = await contactListApi.query({})
        console.log("allContactListsResp", allContactListsResp)
        if (allContactListsResp && !allContactListsResp.error) {
            setAllContactLists(allContactListsResp.results)
        }
    }


    const fetchContactRecipients = async() => {
        if (!reminder?.id) { 
            return
        }
        let contactQuery: any = {reminder_id: reminder?.id, type: "contact"}
        if (searchQuery) { 
            contactQuery.contact_name = searchQuery
        }
        const contactsResp = await recipientApi.query(contactQuery)
        console.error("contactsResp", contactsResp)
        if (contactsResp && !contactsResp.error) {
            const data = contactsResp.results.map(a => {return {recipient_id: a.id, ...a.contact}})
            setContacts(data)
        }
        return contactsResp
    }

    const fetchRecipients = async () => {

        const contactListRecipients = recipientApi.query({reminder_id: reminder?.id, type: "contact_list"})
        // await both
        const [ contactListResp, contactResp ] = await Promise.all([contactListRecipients, fetchContactRecipients()])
        console.error("contactListResp", contactListResp)
        if (contactListResp && !contactListResp.error) {
            const contactList = contactListResp.results[0]
            if (contactList) { 
                const data = {recipient_id: contactList.id, ...contactList.contact_list}
                setContactLists([data])
            }
        }
    }

    useEffect(() => {
        if (searchQuery === "") {
            fetchContactRecipients()
        }
    }, [searchQuery])

    const handleRemoval = async (item, type) => {
        if (type === "contact") { 
            console.log("REMOVING CONTACT", item)
            if (item.recipient_id) {
                const newContacts = contacts?.filter(a => a.recipient_id !== item.recipient_id)
                setContacts(newContacts)
                props.route.params?.onContactsUpdated ? props.route.params.onContactsUpdated(newContacts) : null
            } else { 
                console.log(item.mobile)
                let filter = (a) => a.email !== a.email 
                if (item.mobile) {
                    filter = (a) => a.mobile !== item.mobile
                }
                console.log(contacts)
                const newContacts = contacts?.filter(filter)
                setContacts(newContacts)
                console.log("UPDATING NEW CONTACTS")
                props.route.params?.onContactsUpdated ? props.route.params.onContactsUpdated(newContacts) : null
            }
        } else if (type === "contact_list") { 
            const newContactList = contactLists?.filter(a => a.name !== item.name)
            setContactLists(contactLists.filter(a => a.name !== item.name))
            props.route.params?.onContactListsUpdated ? props.route.params.onContactListsUpdated(newContactList) : null
        }
        if (item.recipient_id) { 
            console.error("DELETING RECIPIENT")
            const resp = await recipientApi.delete(item.recipient_id)
            console.log("delete resp", resp)
            if (!resp || resp.error) { 
                Alert.alert("Error", "Sorry, we couldn't remove that contact from the recipients")
            }
            return resp
        }
    }


    const renderItem = (item) => {
        if (item == 0) {
            return <View>
                <Input  mt={2} rounded={"full"}   InputLeftElement={<Icon as={Feather} name="search" ml="2" size="4" />}  rightElement={<Button variant={"ghost"} onPress={fetchContactRecipients}>Search</Button>} size="lg" placeholder="Search recipients..." value={searchQuery} variant="filled" onChangeText={(text) => setSearchQuery(text) }/>
                <HStack mb={2} mt={2}>
                    <Button rounded="full" variant="subtle" onPress={() => setAddContactModalOpen(true)} >Select contacts</Button>
                </HStack>
            </View>
        } else if (item == 1) { 
            return <View mb={2} mt={2}>
                <Text bold fontSize="lg">Contact list</Text>
                {(!contactLists || contactLists.length == 0) && <Text>No contact list selected..</Text>}
                {contactLists && <FlatList w="100%" data={contactLists} renderItem={(item: any) => <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <Text>{item.item.name}</Text>
                    <Button variant="ghost" onPress={() => handleRemoval(item.item, "contact_list")}>Remove</Button>
                </HStack>} />}
            </View>
        } else if (item == 2) { 
            return <View mb={2} mt={2}>
                <Text bold fontSize="lg">Contacts</Text>
                {(!contacts || contacts?.length == 0) && <Text>No contacts selected..</Text>}
                {contacts && <FlatList w="100%" data={contacts} renderItem={(item: any) => <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <Text>{getContactName(item.item, true, localContactsMap)}</Text>
                    <Button variant="ghost" onPress={() => handleRemoval(item.item, "contact")}>Remove</Button>
                </HStack>} /> }
            </View>
        }
    }


    const addContactListRecipient = async(contactList) => {
        console.log(contactList)
        console.log(reminder?.id)
        var resp = null;
        if (contactList.id) {
            resp = await recipientApi.create({reminder_id: reminder.id, contact_list: contactList.id})
        } else {
            resp = await recipientApi.create({reminder_id: reminder.id, contact_list_body: contactList})
        }
        if (!resp || resp.error) {
            Alert.alert("Error", "Sorry, we couldn't add that contact list to the recipients")
        }
        return resp
    }


    const addContactRecipient = async(contact) => {
        var resp = null;
        if (contact.id) { 
            resp = await recipientApi.create({reminder_id: reminder.id, contact: contact.id})
        } else {
            resp = await recipientApi.create({reminder_id: reminder.id, contact_body: contact})
        }
        if (!resp || resp.error) { 
            Alert.alert("Error", "Sorry, we couldn't add that contact to the recipients")
        }
        return resp
    }

    const onMyContactsFinish = async(selectedContactsMap) => {
        console.error(selectedContactsMap)
        const selectedContacts = Object.values(selectedContactsMap)
        const newContacts = [...contacts, ...selectedContacts]
        var createdRecipient = null
        if (reminder?.id) { 
            for (var contact of newContacts) { 
                await addContactRecipient(contact)
            }
        }
        setContacts(newContacts)
        props.route.params?.onContactsUpdated ? props.route.params.onContactsUpdated(newContacts) : null
    }

    const onManualContactSaved = async(contact) => {
        console.error(contact)
        var updatedContact = contact
        if (reminder?.id) { 
           const recipientResp = await addContactRecipient(contact)
           updatedContact = {recipient_id: recipientResp.id, ...recipientResp.contact}
        }
        const newContacts = [...contacts, updatedContact]
        setContacts(newContacts)
        props.route.params?.onContactsUpdated ? props.route.params.onContactsUpdated(newContacts) : null
    }


    const onNewContactListSelected = async(contactList) => {
        console.log("contact list selected", contactList)
        if (reminder?.id && contactList.id) {
            if (contactLists && contactLists.length > 0 && contactList.id !== contactLists[0].id) {  
                const result = await handleRemoval(contactLists[0], "contact_list");
                if (!result || result.error) { 
                    return;
                }
            }
        }
        if (reminder?.id) {
            await addContactListRecipient(contactList)
        }
        setContactLists([contactList])
        props.route.params?.onContactListsUpdated ? props.route.params.onContactListsUpdated([contactList]) : null
    }
    

    return <Layout>
        <FlatList w="100%" keyboardShouldPersistTaps="always" data={[0, 1, 2]} renderItem={(item) => renderItem(item.item)} />
        
        <ContactAddActionSheet hideImport isOpen={addContactModalOpen} onClose={() => setAddContactModalOpen(false)} onSelect={(type) => {
            if (type === "manual_info") { 
                props.navigation.push("ContactDetail", { onSave: onManualContactSaved })
            } else if (type === "phone_contacts") {
                props.navigation.push("MyContacts", {multiSelect: true, onSelectedMapChange: onMyContactsFinish })
            } else if (type === "contact_list") {
                props.navigation.push("SelectList", { title: "Select contact list", items: allContactLists, onSelect: onNewContactListSelected, 
                renderItem: (item) => {
                    return <Text mt={2} mb={2} fontSize="lg">{item.name}</Text>
                } })
            }
            setAddContactModalOpen(false)
        }} />

    </Layout>
}