import { Button, Center, Heading, Text } from "native-base";
import SimpleModal from "./SimpleModal";
import moment from "moment";
import { AntDesign } from '@expo/vector-icons'; 

export default function ReminderCreatedModal(props) { 
    
    const reminder = props.reminder
    return <SimpleModal isOpen={props.isOpen} onClose={props.onClose} hideHeader notClosable size="lg">
        {props.reminder && <>
            <Center mb={4}>
                <AntDesign name="checkcircle" size={42} color="green" />
            </Center>
            <Heading fontSize="lg" mb="4" textAlign={"center"}>Reminder created!</Heading>
            {props.underReview && <Text color="gray.700" mb={2} textAlign={"center"}>Your reminder is under review and will be sent out once approved!</Text>}
            {!props.underReview && <Text color="gray.700" mb={2} textAlign={"center"}>Your reminder is scheduled for {moment(reminder?.scheduled_at).tz(reminder?.timezone).format("llll")}</Text>}
            {props.showNote && <Text color="gray.700" fontSize="xs" fontWeight={"semibold"} textAlign={"center"}>Note: Any new recipients that are not subscribed to you will recieve the text after accepting your reminder request.</Text>}
            <Button mt={4} onPress={() => props.onClose()}>Done</Button>
            </>}
        </SimpleModal>
}