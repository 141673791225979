import { GiftedChat } from "react-native-gifted-chat";
import Layout from "../components/Layout";
import { Text, View, Image } from 'native-base'
import { useCallback, useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import Placeholder from "../Constants/Placeholder";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Alert } from "react-native";
import Analytics from "../utils/AnalyticsManager";
import { useSelector } from "react-redux";
import { LoadingModal } from "../components/LoadingModal";
import moment from "moment-timezone"

export default function ChatScreen(props) { 

   const chatId = props.route.params?.chatId
   const messageApi = new GenericViewSetAPI("message")
   const user = useSelector((state: any) => state.user)

   const [loading, setLoading] = useState(false)
   const [messages, setMessages] = useState([])
   const [messagesResp, setMessagesResp] = useState(null)
   const [loadingEarlier, setLoadingEarlier] = useState(false)


   const fetchMessages = async() => {
      setLoading(true)
      const messagesResp = await messageApi.query({chat: chatId})
      console.error(messagesResp)
      if (messagesResp && !messagesResp.error) {
         setMessagesResp(messagesResp)
         const chatMessages = messagesResp.results.map(message => commentToMessage(message))
         for (var message of chatMessages) {
            setMessages(previousMessages => GiftedChat.append(previousMessages, message))
         }
      } else { 
         Alert.alert("Error", "Sorry, we couldn't load your messages. Please try again later.")
         props.navigation.goBack()
      }
      setLoading(false)
   }

   useEffect(useCallback(() => {
      Analytics.logEvent("view_page", {page: "ChatScreen"})
   }, []))

   useEffect(() => {
      fetchMessages()
   }, [])

   const renderMessage = (message) => {
      const currMsg = message.currentMessage
      console.log(currMsg)
   
      const isUser = currMsg.comment?.is_from_user == true
      return <View m={3} alignSelf={ isUser? "flex-end" : "flex-start"}  w="50%">
         {currMsg.comment?.image && <Image alignSelf={ isUser ? "flex-end" : "flex-start"} src={currMsg.comment?.image} alt="img" size="2xl" />}
         <View p={4} w="100%" bgColor={isUser ? "blue.500" : 'coolGray.200'}  rounded={"lg"}>
            <Text color={isUser ? "white" : null}>{message.currentMessage.text}</Text>
            <Text mt={2} color={isUser ? "white" : null} fontSize="xs">{moment(message.currentMessage.createdAt).format('lll')}</Text>
         </View>
      </View>
   }

   const onSend = async(text) => {
      const message = {
         text: text, 
      }

      const createResp = await messageApi.create({chat_id: chatId, text: text})
      console.log(createResp)
      if (createResp && !createResp.error) {
         setMessages(previousMessages => GiftedChat.append(previousMessages, [commentToMessage(createResp)]))
      } else {
         Alert.alert("Error", "Sorry, we couldn't send your message. Please try again later.")
      }
   }

   const commentToMessage = (comment) => {
      let message = {
          _id: comment.id,
          text: comment.text,
          createdAt: comment.created_at,
          user: {
              _id: comment.is_from_user ? Placeholder.USER.id : comment.contact?.id ?? -2,
          },
          comment: comment
      }
      return message
  }

   const onLoadEarlier = async() => {

   }


 return <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
   {loading && <LoadingModal visible/>}
    <GiftedChat 
       messages={messages}
       renderMessage={renderMessage}
       onSend={messages => onSend(messages[0].text)}
       loadEarlier={messagesResp?.next != null && messagesResp?.next !== undefined}
       onLoadEarlier={() => onLoadEarlier()}
       isLoadingEarlier={loadingEarlier}
    />
 </SafeAreaView>   
}