import Layout from "../components/Layout";
import { Badge, Button, Divider, FlatList, HStack, Heading, Icon, Input, Pressable, Switch, Text, View } from "native-base";
import Placeholder from '../Constants/Placeholder'
import { useEffect, useLayoutEffect, useState } from "react";
import ContactAddActionSheet from "../components/ContactAddActionSheet";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Alert, Keyboard, Linking } from "react-native";
import { Feather, Ionicons } from "@expo/vector-icons";
import { LoadingModal } from "../components/LoadingModal";
import ConfirmModal from "../components/ConfirmModal";
import { getContactListShareLink, getContactName, getErrorHandlePage, getErrorString, handleRequestError, showAlert } from "../utils";
import { useSelector } from "react-redux";

export default function ContactListDetailScreen(props) { 
    const contactList = props.route.params?.contactList;
    const localContactsMap = useSelector((state: any) => state.localContactsMap)

    const isCreate = !contactList

    const isAllContacts = contactList?.id == -1

    const contactListApi = new GenericViewSetAPI("contact_list")
    const contactsApi = new GenericViewSetAPI("contact")

    const [saving, setSaving] = useState(false)
    const [contactListName, setContactListname] = useState(contactList?.name)
    const [showAddContactActionSheet, setShowAddContactActionSheet] = useState(false)
    const [contactListMembers, setContactListMembers] = useState(contactList?.members ?? [])
    const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [joinable, setJoinable] = useState(contactList?.joinable ?? false)
    const [searchQuery, setSearchQuery] = useState(null)

    useEffect(() => {
        props.navigation.setOptions({title: "List details" ?? "New contact list"})
        if (!isCreate) {
            props.navigation.setOptions({
                headerRight: () => <Button variant="ghost" onPress={() => {
                    setShowAddContactActionSheet(true)
                }}>Add</Button>})
            getContactListMembers()
        } else { 
            props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={() => {
                setSaving(true)
            }}>Create</Button>})
        }
    }, [props.route])


    const getContactListMembers = async() => {
        var query: any = {contact_list: contactList.id}
        if (searchQuery) {
            query.name = searchQuery
        }
        if (contactList.id == "-1") {
            query.contact_list = null
        }
        const contactListMembersResp = await contactsApi.query(query)
        console.log(contactListMembersResp)
        if (contactListMembersResp && !contactListMembersResp.error) { 
            setContactListMembers(contactListMembersResp.results)
        } else { 
            // TODO: Figure this out
        }
    }

    const handleSave = async() => {
        if (isCreate) { 
            const createResp = await getSaveContactListResp()
            console.log(createResp)
            if (createResp && !createResp.error) {
                Keyboard.dismiss();
                props.navigation.navigate("ContactsDraft", {contactList: createResp})
            } else { 
                handleRequestError(createResp, props, true)
            }
        }
        // props.navigation.push("ContactDetail")
        setSaving(false)
    }

    useEffect(() => {
        if (searchQuery == "") {
            getContactListMembers()
        }
    }, [searchQuery])


    useEffect(() => {
        if (saving) { 
           handleSave()
        }
    }, [saving])



    const getSaveContactListResp = async() => {
        const createResp = await contactListApi.create({name: contactListName})
        return createResp;
    }

    const saveContactList = async () => {
        setIsLoadingModalOpen(true);
        if (isCreate) { 
            const createResp = await getSaveContactListResp()
            if (createResp && !createResp.error) { 
                if (!isCreate) { 
                    Alert.alert("Success", "Your contact list has been created")
                }
            } else { 
                handleRequestError(createResp, props, true, "Sorry, we weren't able to create your contact list. Please try again")
            }
        } else { 
            const updateResp = await contactListApi.update(contactList.id, {name: contactListName})
            if (updateResp && !updateResp.error) { 
                Alert.alert("Success", "Your contact list has been updated")
            } else { 
                Alert.alert("Something went wrong", "Sorry, we weren't able to update your contact list. Please try again")
            }
        }
        setIsLoadingModalOpen(false)
    }

    const maybeGetSaveButton = () => {
        if (contactListName != contactList.name)  {
            return <Button variant={"ghost"} size="sm" rounded="none" w="1/6" onPress={() => saveContactList()}>Save</Button>
        }
        return null;
    }

    const updateSearch = () => {
        getContactListMembers()
    }

    const getThemeForStatus = (status) => {
        if (status === "new") { 
            return "info"
        }else if (status === "accepted") {
            return "success"
        } else if (status === "unsubscribed") {
            return "danger"
        } else if (status === "pending") {
            return "warning"
        }
    }

    const renderMemberItems = (item) => {
        // console.log(item.item)
        // showAlert("Fck uuu")
        if (item.item == "search") { 
            return <Input InputLeftElement={<Icon as={Feather} name="search" ml="2" size="4" />} rightElement={<Button variant={"ghost"} onPress={updateSearch}>Search</Button>} mt={2} mb={2} size="lg" placeholder="Search contacts..." value={searchQuery} variant="filled" rounded={"full"} onChangeText={(text) => setSearchQuery(text) } />
        } else { 
            return <Pressable onPress={() => props.navigation.push("ContactDetail", {contact: item.item, onSaveAsync: handleContactSave, onDelete: handleOnDelete})}>
            <View mb={2} mt={2}>
                <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <View>
                        <Text fontSize={"md"}>{getContactName(item.item, false, localContactsMap)}</Text>
                        {item.item.mobile && <Text fontSize="sm" color="gray.600">{item.item.mobile}</Text>}
                        {item.item.email && <Text fontSize="sm" color="gray.600">{item.item.email}</Text>}

                        {item.item.status && <HStack mt={2} mb={2}>
                            <Badge colorScheme={getThemeForStatus(item.item.status)}>{item.item.status}</Badge>
                            </HStack>}
                    </View>
                    {/* <View>
                        <Button colorScheme={"danger"} variant="subtle">Delete</Button>
                    </View> */}
                </HStack>
            </View>
            <Divider />
        </Pressable>
        }
    }

    const updateContactListJoinable = async () => {
        const newJoinable = !joinable
        const oldJoinable = joinable
        setJoinable(newJoinable)
        const updateResp = await contactListApi.update(contactList.id, {joinable: newJoinable})
        if (updateResp && !updateResp.error) { 
            // Alert.alert("Success", "Your contact list has been updated")
            // setJoinable(newJoinable)
        } else { 
            setJoinable(oldJoinable)
            handleRequestError(updateResp, props, true, "Sorry, we weren't able to update your contact list. Please try again")        }
    }

    const renderItem = (item) => {
        if (item == "details") { 
            return <View mb={4}>
             {/* <Heading size="xl" mt={2}>{contactList.name}</Heading> */}
             <Input mt={2} placeholder="List name..." variant="underlined" onChangeText={(text) => setContactListname(text)} fontSize={"30"} fontWeight={"bold"} value={contactListName} InputRightElement={!isCreate && !isAllContacts ? maybeGetSaveButton() : null} />
                {!isCreate && !isAllContacts && <HStack mt={4} justifyContent={"space-between"} alignItems={"center"}>
                    <View>
                        <Text color="coolGray.600">{contactList?.member_count > 0 ? contactList?.member_count + " contact(s)" : "No contacts"}</Text>
                        <HStack space={2} mt={2} alignItems={"center"}>
                            <Text fontSize={"md"}>Joinable</Text>
                            <Switch isChecked={joinable} size="sm" onToggle={(val) => updateContactListJoinable()} /> 
                        </HStack>
                        <Text color="coolGray.700">Enables list sign up through a link</Text>
                        {joinable && <Pressable onPress={() => Linking.openURL(getContactListShareLink(contactList))}><Text fontSize={"xs"} color="coolGray.700" underline>View sign up link</Text></Pressable>}
                    </View>
                    <Button pt={0} pb={0} variant="ghost" colorScheme={"danger"} onPress={() => setDeleteModalOpen(true)}>Delete list</Button>
                </HStack>}
            
            </View>
        } 
        if (item === "members" && contactList) { 
            
            return <View mt={1}>
            {/* {contactListMembers && contactListMembers.length == 0 && <View mt={4}> 
                <Text>Empty list...</Text>
                <HStack mt={2}>
                    <Button onPress={() => setShowAddContactActionSheet(true)}>Add contact to list</Button>
                </HStack>
            </View>} */}
            {/* <Input mt={2} placeholder="Search..." variant="underlined" /> */}
            <FlatList w="100%" keyboardShouldPersistTaps="always"
                data={["search", ...contactListMembers]} renderItem={(item: any) => renderMemberItems(item)} showsVerticalScrollIndicator={false} />
            </View>
        }
    }

    const handleContactSave = async(contactObj) => {
        console.log("HANDLING CONTACT SAVING")
        console.log(contactObj)
        if (!contactObj.id) {
            contactObj.contact_list = contactList?.id
            if (isAllContacts) { 
                contactObj.contact_list = null
            }
            const createResp = await contactsApi.create(contactObj)
            console.log(createResp)
            if (createResp && !createResp.error) { 
                setContactListMembers([...contactListMembers, createResp])
                Alert.alert("Success", "Contact has been successfully created")
            } else { 
                handleRequestError(createResp, props, true, "Sorry, we weren't able to create your contact. Please try again")
            }
        } else { 
            // Update contact
            console.log("UPDATING CONTACT")
            const updateResp = await contactsApi.update(contactObj.id, contactObj)
            console.log(updateResp)
            if (updateResp && !updateResp.error) { 
                setContactListMembers(contactListMembers.map(a => a.id === contactObj.id ? contactObj : a))
                Alert.alert("Success", "Contact has been successfully updated")
            } else { 
                handleRequestError(updateResp, props, true, "Sorry, we weren't able to update your contact. Please try again")
            }
        }
    }

    const deleteList = async() => {
        if (isAllContacts) { 
            return
        }
        setIsLoadingModalOpen(true);
        const deleteResp = await contactListApi.delete(contactList.id)
        if (deleteResp && !deleteResp.error) { 
            Alert.alert("Success", "Your contact list has been deleted")
            props.navigation.goBack()
        } else { 
            handleRequestError(deleteResp, props, true, "Sorry, we weren't able to delete your contact list. Please try again")
        }
        setIsLoadingModalOpen(false)
    }

    const bulkAddContacts = async(c) => {
        var body: any = {"contacts": c, "contact_list_id": contactList?.id}
        if (isAllContacts) { 
            body = {"contacts": c}
        }
        const bulkResp = await contactsApi.nonStandard("POST", "bulk", body)
        console.log(bulkResp)
        if (bulkResp && !bulkResp.error) {
            setContactListMembers([...contactListMembers, ...bulkResp])
        } else { 
            Alert.alert("Something went wrong", "Sorry, we weren't able to add your contacts. Please try again")
        }
    }

    const handleSelectMapChange = async(selectMap) => {
        setIsLoadingModalOpen(true);
        const newContacts = Object.values(selectMap)
        await bulkAddContacts(newContacts)
        setIsLoadingModalOpen(false)
    }

    const handleOnDelete = async(contact) => {
        // find contact
        console.log(contact)
        const contactToDelete = contactListMembers.find((c) => c.id == contact.id)
        console.log(contactToDelete)
        if (!contactToDelete) { 
            return
        }
        // remove contact from contacts list
        const newContacts = contactListMembers.filter((c) => c.id != contact.id)
        console.log(newContacts)
        setContactListMembers(newContacts)
    }

    return <Layout>
        <FlatList keyboardShouldPersistTaps="always" w="100%" data={["details", "members"]} renderItem={(item) => renderItem(item.item)} showsVerticalScrollIndicator={false} /> 

        <ContactAddActionSheet hideImport hideContactList isOpen={showAddContactActionSheet} onClose={() => setShowAddContactActionSheet(false)} onSelect={(type) => {
            if (type === "manual_info") { 
                props.navigation.push("ContactDetail", {onSaveAsync: handleContactSave, onDelete:handleOnDelete })
            } else if (type == "phone_contacts") { 
                props.navigation.push("MyContacts", { isPhoneOnly: true, multiSelect: true, onSelectedMapChange: handleSelectMapChange})
            }

            setShowAddContactActionSheet(false)
        }} />

        <ConfirmModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} headerTitle="Delete list" confirmLabel="Delete" confirmScheme="danger"  text="Are you sure you want to delete this list?" onConfirm={deleteList} />
         <LoadingModal visible={isLoadingModalOpen} />
    </Layout>
}