import BaseApiConfigProvider from './BaseApiConfigProvider';
import BaseServer from './BaseServer'
import { buildURLQuery } from '../utils';
/**
 * Generic view set client for interacting with Django DRF View sets.
 */
export default class GenericViewSetAPI extends BaseServer { 
	private entity: string;

	constructor(entity: string, auth: string = null) { 
		const env = BaseApiConfigProvider.getEnvUrl()
		const appVersion = BaseApiConfigProvider.getAppVersion()
		super(env, appVersion)
		this.entity = entity
	}

	async getById(id: string, params: any = null){ 
		return await super.get(`/${this.entity}/${id}/?${buildURLQuery(params)}`);
	}

	async create(body: any, params: any = null) {
		return await super.post(`/${this.entity}/?${buildURLQuery(params)}`, body)
	}

	async post (body: any, params: any = null) {
		return await super.post(`/${this.entity}/?${buildURLQuery(params)}`, body)
	}

	async update(id: string, body: any) { 
		return await super.patch(`/${this.entity}/${id}/`, body)
	}

	async delete(id: string) { 
		return await super.delete(`/${this.entity}/${id}/`)
	}

	async query(params: any) { 
		return await super.get(`/${this.entity}/?${buildURLQuery(params)}`)
	}

	async nonStandard(method: string, endpoint: string, body: any = null, params: any = null) { 
		return await super.genericRequest(method, `/${this.entity}/${endpoint}/?${buildURLQuery(params)}`, body)
	}

	async postNonStandardNonJson(endpoint: String, body: any) { 
		return await super.nonJsonPost(`/${this.entity}/${endpoint}/`, body)
	}

}