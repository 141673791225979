import { Heading, View, Button, Input, Center, FormControl } from "native-base";
import React, { useEffect, useState } from "react";
import { Platform, TextInput } from "react-native";
import PhoneInput from "react-native-phone-number-input";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { getErrorString, showAlert } from "../utils";
// import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import useViewType from "../hooks/useViewType";
import Recaptcha from 'react-recaptcha-that-works';

import 'react-phone-number-input/style.css'

import * as WebPhoneInput from 'react-phone-number-input'
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";

export default function MobileVerificationVC(props) {

    const userApi = new GenericViewSetAPI("user");

    const [formatText, setFormatText] = useState(props.user?.mobile ?? "");
    const [email, setEmail] = useState(props.user?.email ?? "");
    const [code, setCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false) 
    const [name, setName] = useState(props.name ?? "")
    const [verifyToken, setVerifyToken] = useState(BaseApiConfigProvider.isStage() ? "bullshitcaptcha" : null)

    const viewType = useViewType();
    const [phase, setPhase] = useState(0)

    useEffect(() => {
        // AnalyticsManager.logEvent(events.MOBILE_VERIFY_SHOW, {source: props.source})
    }, [])


    const verifyCode = async () => {
        setIsLoading(true)
        const contact = {
            name: name,
            mobile: formatText,
            email: email ?? null
        }
        console.log("Contact body is", contact)
        let requestBody: any = {code: code, name: contact.name, email: contact.email }
        console.log("contact user id is", props.contactUserId)
        if (props.contactUserId) { 
            requestBody.contact_user_id = props.contactUserId
        } else if (props.listId) {
            requestBody.contact_list = props.listId
        }

        const codeResp = await userApi.nonStandard("POST", "verify_mobile", requestBody);
        if (codeResp && !codeResp.error) { 
            if (props.onFinish) { 
                props.onFinish({resp: codeResp, mobile: formatText, name: name, contact: contact})
            }
        } else { 
            const errorStr = getErrorString(codeResp.error, "Sorry looks like something went wrong. Try again later")
            console.warn(errorStr)
            if (errorStr == "Invalid code") { 
                showAlert("Incorrect code entered. Please try again");
            } else { 
                showAlert(errorStr);
            }
        }
        setIsLoading(false)
    }

    const getCode = async () => { 
        // console.warn(formatText)
        if (!props.isEmail && !formatText) { 
            showAlert("Please enter a valid mobile number");
            return;
        }
        if (props.isEmail && !email) { 
            showAlert("Please enter your email");
            return;
        }

        if (props.showName) {
            if (!name) {  
                showAlert("Please enter your name");
                return;
            }
            // check if name is a number
            const isNameNumbers = !isNaN(Number(name))
            if (isNameNumbers) {
                showAlert("You entered a number for your name. Please enter your your phone number in the correct input field followed by your name");
                return;
            }
        }

        setIsLoading(true)
        const body: any = {mobile: formatText, email: email, contact_list: props.listId, contact_user_id: props.contactUserId}
        if (name) { 
            body.name = name
        }
        if (verifyToken) { 
            body.recaptcha_token = verifyToken
        }
        const codeResp = await userApi.nonStandard("POST", "verify_mobile", body);
        if (codeResp && !codeResp.error) { 
            setPhase(1);
        } else { 
            const errorStr = getErrorString(codeResp.error, "Sorry it looks like something went wrong.")
            console.log("str is ", errorStr)
            if (errorStr.includes("already registered")) {
                showAlert("Sorry, this identifier has already been registered");
            } else { 
                showAlert(errorStr);
            }
        }
        setIsLoading(false)
    }

    const getTitle = () => {
        if (props.title) { 
            return props.title;
        }
        if (props.isEmail) {
            return "Enter your email"
        }
        return "Enter your phone number"
    }


    const onVerify = (token) => { 
        setVerifyToken(token);
    }

    const onExpire = () => {
        setVerifyToken(null)
    }


    const isContinueDisabled = () => {
        if (Platform.OS == "web") { 
            return verifyToken == null
        }
        return false
    }

    return <View bg="white" w="100%">
        {phase === 0 && <View bg="white" >
            {!props.getTitleRender && <Heading mb={props.isEmail ? "1" : "4"} fontWeight={"semibold"}>{getTitle()}</Heading>}
            {props.getTitleRender && props.getTitleRender()}
            {!props.isEmail && viewType === "mobile" && <PhoneInput
                ref={null}
                defaultValue={formatText}
                defaultCode="US"
                layout="first"
                value={formatText}
                onChangeFormattedText={(text) => {
                    setFormatText(text);
                }}
                withDarkTheme
                withShadow
                autoFocus
            />}

            {!props.isEmail && viewType === "desktop" && <WebPhoneInput.default
                    placeholder="Enter phone number"
                    style={{marginTop: 5}}
                    defaultCountry="US"
                    value={formatText}
                    onChange={(text) => setFormatText(text)}/>}

            {props.isEmail && <FormControl isRequired>
                        {/* <FormControl.Label>Email</FormControl.Label> */}
                        <FormControl.HelperText>We'll send you a login code</FormControl.HelperText>
                        <Input size="lg" mt={2} placeholder="awesometraveler@gmail.com..." value={email} onChangeText={(text) => setEmail(text)} />
                </FormControl>}

            {/* Only show this if the guest does not have an invite that tells us their name... */}
            {props.showName && <FormControl isRequired mt={3}>
                        <Input size="lg" placeholder="Your name..." value={name} onChangeText={(text) => setName(text)} />
                </FormControl>}

              <Button mt={4} w="100%" isDisabled={isContinueDisabled()} onPress={getCode} isLoading={isLoading}>Continue</Button>
            
              {Platform.OS == "web" && <View mt={2}>
                    <Recaptcha
                        siteKey="6LeOTTkpAAAAAAP6TQQBAWv4h58UWUWbzmOSJXWS"
                        onVerify={onVerify}
                        onExpire={onExpire}
                    />
                </View>}
            
        </View>}

        {phase === 1 && <View mt={2}>
            {/* <Heading mb={"3"}>Enter the code you were sent</Heading> */}
            <Input size="lg" keyboardType="numeric" placeholder="Enter the code you were sent..." value={code} onChangeText={(text) => setCode(text)} />
            
            <Button mt={"3"} onPress={verifyCode} isLoading={isLoading}>Verify</Button>
            <Button mt={"3"} colorScheme="coolGray" variant="subtle" onPress={() => setPhase(0)}>Go back</Button>

        </View>} 

    </View>
}