import { Button, HStack, View } from "native-base"
import { useState } from "react"
import DateTimePicker from '@react-native-community/datetimepicker';
import { Platform } from "react-native";
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';



export default function SimpleDateTimePicker(props) { 

    const [date, setDate] = useState(props.date ? props.date : new Date())
    
    return <View w="100%">
    {Platform.OS === "ios" && <HStack justifyContent={"flex-end"}>
        {/* <Button variant="ghost" onPress={() => {
            props.onClear()
        }}>Clear</Button> */}
        <Button onPress={() => {
            props.onDone(date);
            setDate(null);
            }}>Done</Button>
    </HStack>}
    <DateTimePicker value={date} mode={"datetime"} display={Platform.OS == "ios" ? "spinner" : null}  onChange={(event, selectedDate) => {
        if (event.type == "dismissed") { 
            props.onDone(null)
        } else  {
            setDate(selectedDate);
            if (Platform.OS === "android") { 
                DateTimePickerAndroid.open({mode: 'time', value: selectedDate, onChange: (event, selectedTime) => { 
                    // setDate(selectedTime);
                    if (event.type === "dismissed") { 
                        props.onDone(null)
                    } else {
                        props.onDone(selectedTime)
                    }
                }})
                // props.onDone(date)
            }
        }
}}  /></View>

}