import { useEffect } from "react";
import { Platform } from "react-native";
            // import {
//     GoogleSignin,
//     statusCodes,
//   } from '@react-native-google-signin/google-signin';
//      
import { getAuth, createUserWithEmailAndPassword, signInWithCredential, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, UserCredential, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { Button } from "native-base";
import GoogleManager from "../utils/GoogleManager";


export default function UniversalSignInWithGoogle(props) { 
    const handleOnPress = async () => {
        if (Platform.OS == "web") { 
            const provider = new GoogleAuthProvider();
            const auth  = getAuth()
            console.log(auth)
            try { 
                const result = await signInWithPopup(auth, provider)
                console.log(result)
                if (props.onCredential) { 
                    props.onCredential(result)
                }
            } catch (error) { 
                props.onError(error)
            }
        } else { 
            console.log("LOOK AT ME MOFO")
            const result = await GoogleManager.handleGoogleSignIn()
            console.log(result)
            if (props.onCredential) { 
                props.onCredential(result)
            }
        }
    }

    useEffect(() => {
    }, [])


    return <>
        <Button w="80%" size="lg" mt={2} onPress={handleOnPress} colorScheme={"darkBlue"} >Sign in with Google</Button>
    </>
    
}