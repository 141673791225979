import { Input, View, FormControl, Button } from "native-base";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import PhoneInput from "react-native-phone-number-input";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { showAlert } from "../utils";
import ConfirmModal from "../components/ConfirmModal";
import { LoadingModal } from "../components/LoadingModal";


export default function ContactDetailScreen(props) { 
    const contact = props.route.params?.contact
    const contactApi = new GenericViewSetAPI("contact")
    
    const [ name, setName ] = useState(contact?.name ?? "")
    const [ phone, setPhone ] = useState(contact?.mobile ? contact?.mobile_context?.national_number.toString() : "")
    const [ email, setEmail ] = useState(contact?.email ?? "")
    const [saveLoading, setSaveLoading] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const onSave = async() => {
        setSaveLoading(true)
        const contactObj: any = {
            id: contact?.id,
            name: name, 
            mobile: phone,
            email: email
        }
        if (contact) {
            contactObj.status = contact.status
        }

        if (props.route.params?.onSave) {
            props.route.params?.onSave(contactObj) 
        }
        if (props.route.params?.onSaveAsync) { 
            await props.route.params?.onSaveAsync(contactObj) 
        }
        setSaveLoading(false)
        props.navigation.goBack();
    }

    const deleteContact = async() => {
        setDeleteLoading(true)
        const deleteResp = await contactApi.delete(contact.id);
        if (deleteResp && !deleteResp.error) { 
            if (props.route.params?.onDelete) {
                props.route.params?.onDelete(contact) 
            }
            props.navigation.goBack();
        } else { 
            showAlert("Sorry, we couldn't delete the contact. Please try again later.")
        }
        setDeleteLoading(false)

    }
    
    useEffect(() => {
        console.log("contact", contact)
        // console.log(contact?.mobile_context.national_number.toString())
    }, [])

    return <Layout>
        <FormControl mt={3}> 
            <FormControl.Label>name</FormControl.Label>
            <Input variant="underlined" size="xl" onChangeText={(text) => setName(text) } placeholder="Enter name..." value={name} />
        </FormControl>


    <FormControl mt={4}>
            <FormControl.Label>Phone</FormControl.Label>
            {/* <Input variant="underlined" size="lg" placeholder="Enter phone number..." value={phone} onChangeText={(txt) => setPhone(txt)} /> */}
            <PhoneInput 
                ref={null}
                defaultValue={phone}
                defaultCode={contact?.mobile_context?.country_code ?? "US"}
                layout="first"
                containerStyle={{width: "100%"}}
                value={phone}
                onChangeFormattedText={(text) => {
                    setPhone(text);
                }}
                withDarkTheme
                withShadow
                textInputProps={{returnKeyType: "done"}}
        />
        </FormControl> 


        <FormControl mt={4}>
            <FormControl.Label>Email</FormControl.Label>
            <Input variant="underlined" size="lg" placeholder="Enter email..." value={email} onChangeText={(txt) => setEmail(txt)}/>
        </FormControl>
        

        <Button mt={4} w="100%" onPress={() => onSave()} isLoading={saveLoading}>Save</Button>
        {contact && <Button mt={4} w="100%" variant="subtle" colorScheme={"danger"} onPress={() => setDeleteModalOpen(true) }>Delete</Button>}

        <ConfirmModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} headerTitle="Delete contact" confirmLabel="Delete" confirmScheme="danger"  text="Are you sure you want to delete this contact?" onConfirm={deleteContact} />
        
        <LoadingModal isOpen={deleteLoading} />
    </Layout>
}