import { FlatList, Heading, View, Text, FormControl, Input, Divider, Pressable, HStack, Button, Modal, Switch} from "native-base";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Placeholder from '../Constants/Placeholder';
import Layout from "../components/Layout";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyFeatureView from "../components/EmptyFeatureView";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import { setConfig, setToken, setUser, setUserAccessJson } from "../store";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Alert } from "react-native";
import { LoadingModal } from "../components/LoadingModal";
import SimpleModal from "../components/SimpleModal";
import ConfirmModal from "../components/ConfirmModal";
import { useFocusEffect } from "@react-navigation/native";
import Analytics from "../utils/AnalyticsManager";
import { getNumberRepresentation, getVersionString, openWebUrl, pickImage, uploadImageAsync } from "../utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hasSku } from "../utils/AccessUtil";
import { Linking } from "react-native";
import { getPhoneBookContacts, showAlert } from "../utils";
import * as Contacts from 'expo-contacts';
import Purchases from "react-native-purchases";


export default function SettingsScreen(props) { 
    const localContactsMap = useSelector((state: any) => state.localContactsMap)
    const user = useSelector((state: any) => state.user)
    const userAccessJson = useSelector((state: any) => state.userAccessJson)
    const config = useSelector((state: any) => state.config)

    const userApi = new GenericViewSetAPI("user")
    const contactsApi = new GenericViewSetAPI("contact")

    const dispatch = useDispatch()

    const [modalOpen, setModalOpen] = useState(false)
    const [modalContext, setModalContext] = useState(null)
    const [emailSender, setEmailSender] = useState(user?.email_sender)
    const [brandName, setBrandName] = useState(user?.brand_name)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [reminderbaseMobile, setReminderbaseMobile] = useState(user?.proxy_number)
    const [impersonateUserToken, setImpersonateUserToken] = useState(null)
    const [contactSyncLoading, setContactSyncLoading] = useState(false)
    const [userPreferences, setUserPreferences] = useState(user?.preferences_json)

    const [isLoading, setIsLoading] = useState(false)

    const getFormRender = (title, value, onPress) => {
        return <><Pressable mt={2} mb={4}>
            <HStack  w="100%" justifyContent={"space-between"} alignItems={"center"}>
                <View>
                    <Text color="gray.600" fontSize="sm">{title}</Text>
                    {value && <Text fontSize="md">{value}</Text>}
                </View>
                <Button variant="link" onPress={onPress}>Edit</Button>
            </HStack>
        </Pressable>
        <Divider />
        </>
    }

    const getModalBody = () => {   
        return <View>
            <FormControl>
                <FormControl.Label>{modalContext?.label}</FormControl.Label>
                <Input value={modalContext?.value} onChangeText={modalContext?.onChange} />
            </FormControl>
        </View>
    }

    const getMe = async () => {
        const [userResp, featureAccessResp] = await Promise.all([userApi.nonStandard("GET", "me"),  userApi.nonStandard("GET", "feature_access")])
        console.log("User response is", userResp)
        if (userResp && !userResp.error) {
            dispatch(setUser(userResp))
            setUserPreferences(userResp.preferences_json)
        }
        if (featureAccessResp && !featureAccessResp.error) {
            dispatch(setUserAccessJson(featureAccessResp))
        }
        console.log(userPreferences)
    }

    useFocusEffect(useCallback(() => {
        Analytics.logEvent("view_page", {page: "SettingsScreen"})
        getMe()
    }, []))

    const updateModalContext = (label, value, onChange) => {
        setModalContext({label: label, value: value, onChange: onChange})
        setModalOpen(true)
    }

    const logOut = async() => {
        await BaseApiConfigProvider.removeUserToken()
        dispatch(setUser(null))
        // dispatch(setConfig(null))
        dispatch(setUserAccessJson(null))
        dispatch(setToken(null))
        Analytics.logEvent("logout")
        Analytics.reset()
        props.navigation.navigate("Signup")
    }

    const getBasicCellView = (label, value, onClick, textStyle={}, customRender=null) => {
        return <Pressable onPress={onClick}><HStack mb={3} mt={3} justifyContent={"space-between"} alignItems={"center"}>
            <Text color="coolGray.700"  style={textStyle}>{label}</Text>
            {customRender ? customRender() : <Text>{value}</Text>}
        </HStack>
        <Divider />
        </Pressable>
    }

    const updateUserPref = async(key, value) => {
        const updatedPrefs = {...userPreferences}
        updatedPrefs[key] = value
        const userUpdateBody = {
            preferences: JSON.stringify(updatedPrefs)
        }
        const oldPrefs = {...userPreferences}
        setUserPreferences(updatedPrefs)
        const userUpdateResp = await userApi.update(user.id, userUpdateBody)
        console.log("userUpdateResp", userUpdateResp)
        if (!userUpdateResp || userUpdateResp.error) {
            showAlert("There was an error updating your preferences")
            setUserPreferences(oldPrefs)
        }

    }

    const handleDelete = async() => {
        setIsLoading(true)
        const userDeleteResp = await userApi.delete(user.id)
        console.log("userDeleteResp")
        console.log(userDeleteResp)
        if (userDeleteResp && !userDeleteResp.error) { 
            dispatch(setUser(null))
            await BaseApiConfigProvider.removeUserToken()
            Alert.alert("Success", "Your account has been deleted")
            props.navigation.navigate("Signup")
        } else { 
            Alert.alert("Error", "There was an error deleting your account")
        }
        setIsLoading(false)
        setDeleteModalOpen(false)
    }


    const handleSave = async () => {
        setIsLoading(true)
        const userUpdateBody = {
            brand_name: brandName,
            email_sender: emailSender
        }
        
        const userUpdateResp = await userApi.update(user.id, userUpdateBody)
        console.log("userUpdateResp")
        console.log(userUpdateResp)
        if (userUpdateResp && !userUpdateResp.error) { 
            dispatch(setUser(userUpdateResp))
            Alert.alert("Success", "Your account has been updated")
        } else { 
            Alert.alert("Error", "There was an error updating your account")
        }
        setIsLoading(false)
        setModalOpen(false)
    }

    const clearAllAsyncKeys = async() => {
        const keys = await AsyncStorage.getAllKeys();
        console.log(keys)
        // await AsyncStorage.multiRemove(keys);
    }

    const checkCanEditPhone = async() => {
        if (!hasSku("custom_phone", userAccessJson)) {
            props.navigation.push("Plans", {feature: "custom phone numbers"})
        } else { 
            props.navigation.push("CustomPhone")
        }
    }

    const restorePurchase = async() => {
        try {
            const restore = await Purchases.restorePurchases();
            console.log("restore is ", restore)
            Alert.alert("Success", "Your purchase has been restored. Restart the app if your purchase is not showing up.")
            Analytics.logEvent("restore_purchase_success")
            const resyncedUser = await userApi.nonStandard("GET", "resync_plan")
            if (resyncedUser && !resyncedUser.error) {
                dispatch(setUser(resyncedUser))
            } 
          } catch (e) {
            console.log(e)
            Analytics.logEvent("restore_purchase_error", {error: e})
            if (!e.userCancelled) {
                Alert.alert("Sorry", "We couldn't restore your purchase. Please contact support@reminderbase.com for help if this persists")
            }
          }
    }

    const syncPhoneContacts = async () => {
        setContactSyncLoading(true)
        let contacts = []
        if (localContactsMap) {
            contacts = localContactsMap.full
        } else {
            const { status } = await Contacts.requestPermissionsAsync();
            if (status == "granted") {
                contacts = await getPhoneBookContacts()
            }
        }
        console.log(contacts)
        // contacts = contacts.map((contact) => {}
        const resp = await contactsApi.nonStandard("POST", "bulk", {contacts: contacts, sync_only: true})
        if (resp && !resp.error) {
            Alert.alert("Success", "Your contacts have been synced")
        } else {
            Alert.alert("Error", "There was an error syncing your contacts")
        }
        setContactSyncLoading(false)
    }

    return <Layout scrollView>
        {user && <View w="100%">
            <View  w="100%" mt={8}>
                {user.name && <Heading textAlign={"center"}>{user.name}</Heading>}
                <Text textAlign={"center"}>{user.email}</Text>
                <Text textAlign={"center"}>{user.mobile}</Text>

                {/* <Button variant="ghost">Edit</Button> */}
            </View>

            <Divider mt={3}/>

            {getFormRender("Your plan", user.plan_name ?? "Free plan", () => props.navigation.push("Plans"))}

            {/* {getFormRender("Email sender", user.email_sender + "@reminderbase.com", () => updateModalContext("Email sender", emailSender, (text) => setEmailSender(text)))} */}

            {getFormRender("Brand name", brandName, () => updateModalContext("Brand name", brandName, (text) => setBrandName(text)))}

            {getFormRender("Reminderbase phone number", reminderbaseMobile, checkCanEditPhone )}

            {getBasicCellView("SMS Credit Remaining", getNumberRepresentation(user?.sms_limit ?? 0), () => null)}

            {getBasicCellView("MMS Credit Remaining",  getNumberRepresentation(user?.mms_limit ?? 0), () => null)}

            {getBasicCellView("Email Credit Remaining",  getNumberRepresentation(user?.email_limit ?? 0), () => null)}

            {getBasicCellView("Email notifications", userPreferences?.email_notification, () => null, {}, () => <Switch value={userPreferences?.email_notification ?? false} size="sm" onValueChange={(value) => updateUserPref("email_notification", value) } />)}

            {getBasicCellView("Restore purchases", "", () => restorePurchase(), true )}

            {getBasicCellView("Request feature", "", () => openWebUrl("https://reminderbaseapp.canny.io/feature-requests", true))}

            {config?.sync_user_ids && config?.sync_user_ids.includes(user.id) && getBasicCellView("Sync your phone contacts", contactSyncLoading ? "Loading..." : "", () => syncPhoneContacts())}

            {getBasicCellView("Contact us", "", () => openWebUrl("https://www.reminderbase.com/contact", true))}
             
            {getBasicCellView("Terms of service", "", () => Linking.openURL("https://www.reminderbase.com/terms-of-service"))}

            {getBasicCellView("Privacy policy", "", () => Linking.openURL("https://www.reminderbase.com/privacy-policy"))}

            {getBasicCellView("App version",  getVersionString() , () => null)}

            {getBasicCellView("API env", BaseApiConfigProvider.getEnvType() , () => null)}


            {getBasicCellView("Log out", "", () => logOut(), {marginTop: 40})}

            {getBasicCellView("Delete your account", "", () => setDeleteModalOpen(true), {color: "red"})}

            {(user.is_staff || __DEV__) &&  <View mt={4}>
                <Heading mb={2}>Admin menu</Heading>    
                <Button variant="link" onPress={() => {
                    Alert.alert("Change env", "Select your environment", [
                        {text: "Production", onPress: () => BaseApiConfigProvider.changeEnv("PROD")},
                        {text: "Test", onPress: () => BaseApiConfigProvider.changeEnv("STAGE")},
                        {text: "Local", onPress: () => BaseApiConfigProvider.changeEnv("LOCAL")},
                        {text: "Cancel", onPress: () => null, style: 'cancel'}
                    ], 
                    {
                        cancelable: true,
                        onDismiss: () =>
                            Alert.alert(
                            'This alert was dismissed by tapping outside of the alert dialog.',
                            ),
                    })
                }}>Change Env</Button>

                <Button onPress={() => { throw new Error("Test exception")}}>Trigger Exception</Button>
                
                <FormControl>
                    <FormControl.Label>User token</FormControl.Label>
                    <Input value={impersonateUserToken} onChangeText={(text) => setImpersonateUserToken(text)} />
                    <Button variant={"subtle"} onPress={() => BaseApiConfigProvider.saveUserToken(impersonateUserToken)}>Impersonate</Button>
                </FormControl> 


                <Button onPress={async() => {
                    const image = await pickImage()
                    console.log(image)
                    const upload = await uploadImageAsync(image, user.id)
                    if (upload) { 
                        Alert.alert("Success", "Image uploaded")
                    } else { 
                        Alert.alert("Error", "There was an error uploading your image")
                    }
                }}>Test image upload</Button>

                <Button variant={"link"} onPress={() => clearAllAsyncKeys()}>Clear cache</Button>
            </View>}

            
            <HStack mt={2} space={2}>
                {/* <Button onPress={() => props.navigation.push("Signup")}>Sign up</Button> */}
                {/* <Button onPress={() => logOut()}>Log out</Button> */}
            </HStack>


            <Modal size="lg" isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>Update</Modal.Header>
                    <Modal.Body>
                        {getModalBody()}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button.Group variant="ghost" space={2}>
                        <Button onPress={() => setModalOpen(false)}>Cancel</Button>
                        <Button onPress={() => handleSave()}>Save</Button>
                    </Button.Group>
                </Modal.Footer>
                </Modal.Content>
            </Modal>
        </View>}

        <ConfirmModal headerTitle="Delete your account" confirmScheme="danger" confirmLabel="Delete" isOpen={deleteModalOpen}  onClose={() => setDeleteModalOpen(false)} onConfirm={handleDelete} text="Are you sure? This cannot be undone. You will lose all of your data including reminders" />

        <LoadingModal visible={isLoading} />

        { !user && <EmptyFeatureView heading="Create an account to manage settings" label="Creating an account lets you have control over how your reminders are sent and so much more" buttonLabel="Create account" onPress={() => props.navigation.push("Signup")} />}
    </Layout>
}